import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./medicos.css";
import Alert from "../../modal/alert";

class EsperaAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cedula: "",
      cedula2: "",
      nombre: "",
      especialista: "",
      horario: "",
      ifechaespera1: [],
      ifechaespera2: [],
      fechaespera: moment().format("YYYY-MM-DD"),
      clientetrue: false,
      fechaNacimiento: "",
      //valorini:'1',
      modificarOn: false,
      iddonante: "",
      reemplazarOn: false,
      iterapeuta: [],
      terapeutaV: "",
      terapeutaN: "",
      sede: "",
    };
    moment.locale("es");
  }

  limpiar = () => {
    this.setState({
      cedula: "",
      cedula2: "",
      nombre: "",
      especialista: "",
      horario: "",
      //valorini:'1',
      ifechaespera1: [],
      ifechaespera2: [],
      fechaespera: moment().format("YYYY-MM-DD"),
      fechaNacimiento: "",
      modificarOn: false,
      iddonante: "",
      reemplazarOn: false,
      terapeutaV: "",
      terapeutaN: "",
      sede: "",
    });
    this.buscarMedicos();
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  cerrarlistadocli() {
    this.setState({
      ifechaevo: [],
      clientetrue: false,
    });
  }

  consultarnombre(e) {
    if (this.state.cedula.length === 0) {
      let canit = 0;
      let resul = 0;
      axios
        .post(
          "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?cedula=" +
            this.state.nombre +
            "&valorini=" +
            this.state.valorini +
            "&boton=Consultarnombre"
        )
        //console.log('https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?cedula=' + this.state.nombre + '&valorini='+ this.state.valorini +'&boton=Consultarnombre')
        .then((res) => {
          if (res.data) {
            //console.log("paso:" +res.data)

            let xfechaevo = res.data;
            //canit = xfechaevo.map(p => p.variable4);
            canit = xfechaevo.slice(0, 1).map((p) => p.variable4);
            if (parseFloat(canit) / 20 > Math.round(parseFloat(canit) / 20)) {
              resul = Math.round(parseInt(canit) / 20) + 1;
            } else {
              resul = Math.round(parseInt(canit) / 20);
            }

            this.setState({
              cantitem: canit,
              cantpagi: resul,
              ifechaevo: xfechaevo,
              clientetrue: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Maestro de Especialistas",
              cuerpoM:
                "No se encontraron pacientes con esos datos, verifique Gracias.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Maestro de Especialistas",
        cuerpoM: "No se encontraron pacientesDebe Gracias.",
      });
    }
  }

  consultar() {
    if (this.state.cedula.length > 0) {
      axios
        .post(
          "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?cedula=" +
            this.state.cedula +
            "&boton=Consultar"
        )
        .then((res) => {
          if (res.data) {
            this.setState({
              cedula: res.data.variable1,
              cedula2: res.data.variable1,
              nombre: res.data.variable2,
              especialista: res.data.variable3,
              clientetrue: false,
            });
            //this.buscarcitaspacientes();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Maestro de Especialistas",
              cuerpoM:
                "No se encontro la cedula que se desea consultar, verifique Gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Maestro de Especialistas",
        cuerpoM:
          "Debe Introducir un numero de cedula para poder realizar una consulta Gracias...",
      });
    }
  }

  consultar2(e, f) {
    let data = new FormData();
    data.append("cedula", e);
    data.append("boton", "Consultar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data) {
          this.setState({
            cedula: res.data.variable1,
            cedula2: res.data.variable1,
            nombre: res.data.variable2,
            especialista: res.data.variable3,
            fechaNacimiento: res.data.variable4,
            sede: res.data.variable5,
            clientetrue: false,
            modificarOn: true,
            iddonante: f,
          });
          //this.buscarcitaspacientes();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Maestro de Especialistas",
            cuerpoM:
              "No se encontro la cedula que se desea consultar, verifique Gracias...",
          });
        }
      });
  }

  guardar = () => {
    if (this.state.cedula.length > 0) {
      axios
        .post(
          "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?cedula=" +
            this.state.cedula +
            "&especialista=" +
            this.state.especialista +
            "&nombre=" +
            this.state.nombre +
            "&horario=" +
            this.state.horario +
            "&fechaNaci=" +
            this.state.fechaNacimiento +
            "&sede=" +
            this.state.sede +
            "&boton=Grabar"
        )
        //console.log('https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?codigo='+fech1+'&cedula='+this.state.cedula+'&especialista='+this.state.especialista+'&horario='+this.state.horarioespera+'&boton=Agregar')
        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Maestro de Especialistas",
              cuerpoM: "Se Guardo el Registro Correctamente",
            });
            this.buscarMedicos();
            //this.buscarcitaspacientes();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Maestro de Especialistas",
              cuerpoM:
                "El Registro ya Existe o La Cedula no Coincide con la del Sistema Administrativo, verifique Gracias...",
            });
          }
        });
    }
  };

  eliminar = (e) => {
    if (e !== "") {
      axios
        .post(
          "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?codigo=" +
            e +
            "&cedula=" +
            e +
            "&especialista=&horario=&boton=Eliminar"
        )
        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Maestro de Especialistas",
              cuerpoM: "El Registro fue eliminada con exito",
            });
            this.buscarMedicos();
            //this.buscarcitaspacientes();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Maestro de Especialistas",
              cuerpoM:
                "Ocuriio un error, no se pudo eliminar el registro, verifique por favor",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Maestro de Especialistas",
        cuerpoM: "Ocuriio un error, verifique por favor",
      });
    }
  };

  buscarMedicos = () => {
    let data = new FormData();
    data.append("boton", "Buscar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data) {
          let xifechaespera1 = res.data;
          this.setState({
            ifechaespera1: xifechaespera1,
          });
        } else {
          this.setState({
            ifechaespera1: [],
          });
        }
      });
  };

  getButtons = () => {
    let botones = [];
    let bandera = 0;
    //console.log(this.state.cantpagi+"-"+this.state.paginactiva)
    botones.push(
      <button
        className={
          parseInt(this.state.paginactiva) > 10
            ? "botonspanOf"
            : "botonspanocultar"
        }
        name="izquierdaS"
        onClick={this.busquedapagi}
      >
        &lt;
      </button>
    );

    for (let i = 0; i < this.state.cantpagi; i++) {
      bandera = 0;
      if (
        i + 1 >= parseInt(this.state.desde) &&
        i + 1 <= parseInt(this.state.hasta)
      ) {
        bandera = 1;
      }
      botones.push(
        <button
          className={
            parseInt(this.state.paginactiva) === i + 1
              ? "botonspanOf"
              : "botonspan"
          }
          name={parseInt(i) + 1}
          onClick={this.busquedapagi}
          id={bandera === 0 ? "ocultar" : ""}
        >
          {parseInt(i) + 1}
        </button>
      );
    }

    botones.push(
      <button
        className={
          parseInt(this.state.cantpagi) > 10
            ? "botonspanOf"
            : "botonspanocultar"
        }
        name="derechaS"
        onClick={this.busquedapagi}
      >
        &gt;
      </button>
    );
    botones.push(
      <button
        className={
          parseInt(this.state.cantpagi) > 10
            ? "botonspanOf"
            : "botonspanocultar"
        }
        name="derechaD"
        onClick={this.busquedapagi}
      >
        &gt;&gt;
      </button>
    );

    return botones;
  };

  busquedapagi = (e) => {
    let spaso = 0;
    let numepag = 0;
    let desde1 = 0;
    let hasta1 = 0;
    let resulpaghasta = 0;
    let resulpagdesde = 0;
    let difehasta = 0;
    let paso = 0;

    if (e.target.name === "izquierdaS") {
      spaso = 1;
      numepag = parseInt(this.state.paginactiva) - 1;
      if (numepag === 0) numepag = 1;
    }
    if (e.target.name === "derechaS") {
      spaso = 1;
      numepag = parseInt(this.state.paginactiva) + 1;
      if (numepag > parseInt(this.state.cantpagi))
        numepag = parseInt(this.state.cantpagi);
    }
    if (e.target.name === "derechaD") {
      spaso = 1;
      numepag = parseInt(this.state.cantpagi);
    }

    if (parseInt(spaso) === 0) {
      numepag = parseInt(e.target.name);
    }

    resulpaghasta = parseInt(this.state.cantpagi) - numepag;

    if (resulpaghasta >= 4) {
      hasta1 = numepag + 4;
      difehasta = 0;
    }
    if (resulpaghasta < 4 && resulpaghasta > 0) {
      hasta1 = numepag + resulpaghasta;
      difehasta = 4 - resulpaghasta;
    }
    if (resulpaghasta <= 0) {
      hasta1 = numepag;
      difehasta = 4;
    }
    resulpagdesde = numepag - 1;

    if (resulpagdesde <= 0) desde1 = 1;

    if (resulpagdesde < 5 && resulpagdesde > 0) {
      if (difehasta > 0) {
        if (resulpagdesde <= difehasta) {
          desde1 = numepag - resulpagdesde;
          resulpagdesde = 0;
        }
        if (resulpagdesde > difehasta) {
          desde1 = numepag - difehasta;
          resulpagdesde = resulpagdesde - difehasta;
        }
      }
      if (difehasta <= 0) desde1 = numepag;
    }
    if (resulpagdesde < 5 && resulpagdesde > 0) desde1 = desde1 - resulpagdesde;

    if (resulpagdesde >= 5) {
      if (difehasta > 0) {
        desde1 = numepag - difehasta;
        resulpagdesde = resulpagdesde - difehasta;
        paso = 1;
      }
      if (difehasta <= 0) desde1 = numepag - 5;

      if (resulpagdesde >= 5 && paso === 1) desde1 = desde1 - 5;
      if (resulpagdesde >= 1 && resulpagdesde < 5 && paso === 1)
        desde1 = desde1 - resulpagdesde;
    }

    if (resulpagdesde < 5) hasta1 = hasta1 + (5 - resulpagdesde);

    axios
      .post(
        "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?cedula=" +
          this.state.nombre +
          "%&valorini=" +
          numepag +
          "&boton=Consultarnombre"
      )
      //if (numepag===2) console.log('https://institutobilinguelasnaciones.com/terapias/backend/medicos.php/?cedula=' + this.state.nombre + '%&valorini='+ numepag +'&boton=Consultarnombre')
      .then((res) => {
        if (res.data) {
          let xfechaevo = res.data;
          this.setState({
            ifechaevo: xfechaevo,
            clientetrue: true,
            paginactiva: numepag,
            desde: desde1,
            hasta: hasta1,
          });
        }
      });
  };

  modificar = () => {
    if (this.state.iddonante.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.iddonante);
      data.append("cedula", this.state.cedula);
      data.append("especialista", this.state.especialista);
      data.append("nombre", this.state.nombre);
      data.append("fechaNacimiento", this.state.fechaNacimiento);
      data.append("sede", this.state.sede);
      data.append("boton", "modificar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarMedicos();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Donantes.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Donantes.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Maestro de Donantes.",
        cuerpoM: "Ocurrio un Error, verifique por Favor.",
      });
    }
  };

  reemplazar = () => {
    if (this.state.reemplazarOn) {
      this.setState({ reemplazarOn: false });
    } else {
      this.setState({ reemplazarOn: true });
    }
  };

  buscarE = () => {
    let data = new FormData();
    data.append("boton", "Buscar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);

        let xterapeuta = res.data;
        this.setState({
          iterapeuta: xterapeuta,
        });
      });
  };

  procesar = () => {
    let data = new FormData();
    data.append("cedulaV", this.state.terapeutaV);
    data.append("cedulaN", this.state.terapeutaN);
    data.append("boton", "reemplazar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            terapeutaV: "",
            terapeutaN: "",
            mensajealerta: true,
            tituloM: "Menu Maestro Especialistas.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Maestro Especialistas.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarMedicos();
    this.buscarE();
  }

  render() {
    return (
      <div className="cont-principal-espera row">
        <div className="cont-espera col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.reemplazarOn === true && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="tituloespera">Reemplazar Especialistas</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.reemplazar.bind(this)}
                  >
                    X
                  </span>
                </div>

                <div className="cont-listmedi row">
                  <div className="form-group col-12">
                    <select
                      id="terapeutaV"
                      name="terapeutaV"
                      value={this.state.terapeutaV}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Terapeuta a Retirar-</option>
                      {this.state.iterapeuta.map((item, i) => {
                        return (
                          <option key={i} value={item.variable2}>
                            {item.variable3}
                          </option>
                        );
                      })}
                    </select>
                    <span> </span>
                    <select
                      id="terapeutaN"
                      name="terapeutaN"
                      value={this.state.terapeutaN}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Terapeuta Nuevo-</option>
                      {this.state.iterapeuta.map((item, i) => {
                        return (
                          <option key={i} value={item.variable2}>
                            {item.variable3}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="cont-listmedi row">
                  <div className="form-group col-12">
                    <button
                      className="botonre"
                      onClick={this.procesar.bind(this)}
                    >
                      Procesar
                    </button>
                    <button
                      className="botonre"
                      onClick={this.reemplazar.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="cont-titulo-espera row">
            <div className="col-12">
              <span className="tituloespera">Maestro de Especialistas.</span>
            </div>
            <div className="cont-datos-espera col-12">
              <div className="row">
                <div className="cont-cedula col-3">
                  <label className="opcion">Cedula.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="cedula"
                    id="cedula"
                    autoComplete="off"
                    value={this.state.cedula}
                    onChange={this.onChange.bind(this)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.consultar();
                      }
                    }}
                  />
                  {/* <i
                    className="icono-izquierdac fa fa-search"
                    onClick={this.consultar.bind(this)}
                  >
                    {" "}
                  </i> */}
                </div>
                <div className="cont-cedula col-5">
                  <label className="opcion">Nombre.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="nombre"
                    id="nombre"
                    autoComplete="off"
                    value={this.state.nombre}
                    onChange={this.onChange.bind(this)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.consultarnombre();
                      }
                    }}
                  />
                  {/* <i
                    className="icono-izquierdac2 fa fa-search"
                    onClick={this.consultarnombre.bind(this)}
                  >
                    {" "}
                  </i> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="cont-motivo-consulta row">
                <div className="cont-motivo col-5">
                  <label className="opcion">Especialista.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="especialista"
                    id="especialista"
                    autoComplete="off"
                    value={this.state.especialista}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
                <div className="cont-motivo col-3">
                  <label className="opcion">F/Nacimiento.</label>
                  <input
                    className="form-control"
                    type="date"
                    name="fechaNacimiento"
                    id="fechaNacimiento"
                    autoComplete="off"
                    value={this.state.fechaNacimiento}
                    onChange={this.onChange.bind(this)}
                  />
                </div>

                <div className="acciones col-4">
                  <button
                    className={this.state.modificarOn ? "botonOf" : "boton"}
                    onClick={
                      this.state.modificarOn ? "" : this.guardar.bind(this)
                    }
                  >
                    Guardar
                  </button>
                  <button
                    className={this.state.modificarOn ? "boton" : "botonOf"}
                    onClick={
                      this.state.modificarOn ? this.modificar.bind(this) : ""
                    }
                  >
                    Modificar
                  </button>
                  <button
                    className="boton"
                    onClick={this.reemplazar.bind(this)}
                  >
                    Reemplazar Especialista
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="cont-datos-espera col-12">
              <div className="row">
                <div className="cont-personal col-2">
                  <label className="opcion">Sede.</label>

                  <select
                    id="sede"
                    name="sede"
                    value={this.state.sede}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-Seleccione Sucursal-</option>
                    <option value="1">Bella Vista</option>
                    <option value="2">Arraijan</option>
                    <option value="3">Chorrera</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cont-principal-tablas col-12">
          <div className="row">
            <div className="cont-general-citas col-12">
              <span className="cont-span-cita">Medicos o Lic. Registrados</span>
              <div className="listadocasos">
                <table className="tablaespera1">
                  <thead>
                    <tr>
                      <th className="tituevo1">Cedula</th>
                      <th className="tituevo1">Nombre</th>
                      <th className="tituevo1">Especialista</th>
                      <th className="tituevo1">F/Nacimiento</th>
                      {/* <th className="tituevo1">Horario</th> */}
                      <th className="tituevo1">
                        <span className="cerrarme5"></span>
                        <span className="cerrarme3"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cuerpoespera1">
                    {this.state.ifechaespera1.map((item, i) => {
                      //console.log("paso "+i);

                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={item.variable1}
                        >
                          <td
                            width="150"
                            className="itemtabla1"
                            key={item.variable1}
                          >
                            {item.variable2}
                          </td>
                          <td width="250" className="itemtabla1">
                            {item.variable3}
                          </td>
                          <td width="250" className="itemtabla1">
                            {item.variable4}
                          </td>
                          <td width="100" className="itemtabla1">
                            {item.variable5}
                          </td>
                          <td width="20">
                            <span
                              className="cerrarme5"
                              onClick={this.consultar2.bind(
                                this,
                                item.variable2,
                                item.variable1
                              )}
                            ></span>
                            <span
                              className="cerrarme2"
                              onClick={this.eliminar.bind(this, item.variable2)}
                            ></span>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EsperaAction;
