import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import FeatherIcon from "feather-icons-react";

//componentes
import "./cita.css";
import Alert from "../../modal/alert";
import logo1 from "../../imagenes/Logo.jpg";
import Recibo from "../recepcion/recepcion";
class CitasAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
      xtotales: "",
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      iterapeuta: [],
      terapeuta: "",

      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      sabadoI: 6,
      domingoI: 7,
      fechaSeleccionada: new Date(),
      fechaInicio: new Date(),
      icasos: [],
      imedicos: [],
      casos: "",
      casos2: "",
      medicos: "",
      tipoFecha: "2",
      fechaIni: "",
      fechaFin: "",
      fechaDescansoI: "",
      fechaDescansoF: "",
      verHorarioOn: false,
      ihorario: [],
      dia1: "",
      dia2: "",
      dia3: "",
      dia4: "",
      dia5: "",
      dia6: "",
      diaI: "",
      diaF: "",
      incluirdatosOn: false,
      alumno: "",
      horaS: "",
      fechaT: moment().format("YYYY-MM-DD"),
      confirmadoOn: false,
      ayudaOn: false,
      enconsultaOn: false,
      dolarOn: false,
      canceladoOn: false,
      noasistioOn: false,
      esperaOn: false,
      nocobradoOn: false,
      donacionOn: false,
      estatusOn: false,
      numeroEstatus: "",
      idasociar: "",
      fechaActual: "",
      fechadeldia: moment().format("YYYY-MM-DD"),
      tipoEstudio: "",
      tipoEva: "",
      iultimaCita: [],
      idiasDis: [],
      diasDis: "",
      nota: "",
      notaOn: false,
      cambioOn: false,
      horaC1: "",
      diaCalendario: "",
      fechaNuevaCita: "",
      cambioEstudioOn: false,
      cambioAnuladoOn: false,
      facturaNoAsitioOn: false,
      bandera: "",
      correrF: "",
      habilitarReciOn: false,
      recibosE: "",
      irecibosE: [],
      sede: "",
      tipoEstu: "",
      estatusAnterior: "",
      facturasAtrasadas: "",
      ifacturasAtrasadas: [],
      facturasAtrasadasOn: false,
      autorizado:'',
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      terapeuta: "",
      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      sabadoI: 6,
      domingoI: 7,
      fechaSeleccionada: new Date(),
      fechaInicio: new Date(),
      casos: "",
      casos2: "",
      medicos: "",
      tipoFecha: "1",
      fechaIni: "",
      fechaFin: "",
      fechaDescansoI: "",
      fechaDescansoF: "",
      verHorarioOn: false,
      ihorario: [],
      dia1: "",
      dia2: "",
      dia3: "",
      dia4: "",
      dia5: "",
      dia6: "",
      diaI: "",
      diaF: "",
      incluirdatosOn: false,
      alumno: "",
      horaS: "",
      fechaT: moment().format("YYYY-MM-DD"),
      confirmadoOn: false,
      ayudaOn: false,
      enconsultaOn: false,
      dolarOn: false,
      canceladoOn: false,
      noasistioOn: false,
      esperaOn: false,
      nocobradoOn: false,
      donacionOn: false,
      estatusOn: false,
      numeroEstatus: "",
      idasociar: "",
      fechaActual: "",
      tipoEstudio: "",
      tipoEva: "",
      iultimaCita: [],
      idiasDis: [],
      diasDis: "",
      nota: "",
      notaOn: false,
      cambioOn: false,
      horaC1: "",
      diaCalendario: "",
      fechaNuevaCita: "",
      cambioEstudioOn: false,
      cambioAnuladoOn: false,
      facturaNoAsitioOn: false,
      bandera: "",
      correrF: "",
      habilitarReciOn: false,
      recibosE: "",
      irecibosE: [],
      sede: "",
      tipoEstu: "",
      estatusAnterior: "",
      facturasAtrasadas: "",
      ifacturasAtrasadas: [],
      facturasAtrasadasOn: false,
      autorizado:'',
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "casos") {
      this.terapeutasDisponibles(e.target.value);
    }
    if (e.target.name === "medicos") {
      // this.consultarHorarioEspecialista(e.target.value);
    }
    if (e.target.name === "sede") {
      this.setState({
        casos: "",
        medicos: "",
        imedicos: [],
        verHorarioOn: false,
        ihorario: [],
      });
    }

    if (e.target.name === "tipoFecha") {
      if (e.target.value === "2") {
        this.state.tipoFecha = "2";
        this.valorInicial(e.target.value);
        this.consultarHorarioEspecialista(this.state.medicos);
      }
      if (e.target.value === "1") {
        this.state.tipoFecha = "1";
        this.valorInicial("1");
        this.consultarHorarioEspecialista(this.state.medicos);
      }
    }

    if (e.target.name === "numeroEstatus") {
      if (
        e.target.value !== "4" &&
        e.target.value !== "5" &&
        e.target.value !== "6" &&
        e.target.value !== "10" &&
        e.target.value !== "11" &&
        e.target.value !== "14" &&
        e.target.value !== "15" &&
        e.target.value !== "16"
      ) {
        this.actualizarEstatus(e.target.value);
        this.setState({
          estatusOn: false,
        });
      }
      if (e.target.value === "4") {
        if (this.state.facturasAtrasadas === "0") {
          this.setState({
            estatusOn: false,
            habilitarReciOn: true,
          });
        } else {
          this.setState({
            estatusOn: false,
            facturasAtrasadasOn: true,
          });
        }
      }
      if (e.target.value === "5") {
        this.setState({
          nota: "",
          cambioAnuladoOn: true,
        });
      }
      if (e.target.value === "6" || e.target.value === "10") {
        this.setState({
          nota: "",
          notaOn: true,
        });
      }
      if (e.target.value === "11") {
        this.setState({
          nota: "",
          cambioOn: true,
        });
      }
      if (e.target.value === "15") {
        this.setState({
          nota: "",
          facturaNoAsitioOn: true,
        });
      }
      if (e.target.value === "16") {
        this.setState({
          nota: "",
          facturaNoAsitioOn: true,
        });
      }
      if (e.target.value === "14") {
        this.setState({
          nota: "",
          cambioEstudioOn: true,
        });
      }
    }
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }
  onChange4(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        correrF: "SI",
      });
    } else {
      this.setState({
        correrF: "",
      });
    }
  }
  onChange5 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    let data = new FormData();
    data.append("numeroRecibo", e.target.value);
    data.append("tipoFact", "SERVICIOS");
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago4.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  habilitarRecibo = () => {
    if (this.state.habilitarReciOn) {
      this.consultarHorarioEspecialista(this.state.medicos);
      this.setState({
        habilitarReciOn: false,
      });
    }
    if (!this.state.habilitarReciOn) {
      this.setState({
        habilitarReciOn: true,
      });
    }
  };

  facturaNoAsitio = () => {
    if (this.state.facturaNoAsitioOn) {
      this.setState({
        estatusOn: false,
        facturaNoAsitioOn: false,
        bandera: "",
      });
    }
    if (!this.state.facturaNoAsitioOn) {
      this.setState({
        facturaNoAsitioOn: true,
      });
    }
  };

  facturasAt = () => {
    if (this.state.facturasAtrasadasOn) {
      this.setState({
        facturasAtrasadasOn: false,
      });
    }
    if (!this.state.facturasAtrasadasOn) {
      this.setState({
        facturasAtrasadasOn: true,
      });
    }
  };

  consutarNotas = () => {
    if (this.state.notaOn) {
      this.setState({
        estatusOn: false,
        notaOn: false,
        bandera: "",
      });
    }
    if (!this.state.notaOn) {
      this.setState({
        notaOn: true,
      });
    }
  };

  consutarCambios = () => {
    if (this.state.cambioOn) {
      this.setState({
        estatusOn: false,
        cambioOn: false,
        bandera: "",
      });
    }
    if (!this.state.cambioOn) {
      this.setState({
        cambioOn: true,
      });
    }
  };

  cambiarEstu = () => {
    if (this.state.cambioEstudioOn) {
      this.setState({
        estatusOn: false,
        cambioEstudioOn: false,
        bandera: "",
      });
    }
    if (!this.state.cambioEstudioOn) {
      this.setState({
        cambioEstudioOn: true,
      });
    }
  };
  cambiarAnu = () => {
    if (this.state.cambioAnuladoOn) {
      this.setState({
        estatusOn: false,
        cambioAnuladoOn: false,
        bandera: "",
      });
    }
    if (!this.state.cambioAnuladoOn) {
      this.setState({
        cambioAnuladoOn: true,
      });
    }
  };

  guardarEstatusAnu = () => {
    this.setState({
      notaOn: false,
      estatusOn: false,
      cambioAnuladoOn: false,
      bandera: "",
    });
    this.actualizarEstatus(this.state.numeroEstatus);
  };

  guardarNotas = () => {
    this.setState({
      notaOn: false,
      estatusOn: false,
      bandera: "",
    });
    this.actualizarEstatus(this.state.numeroEstatus);
  };

  guardarFacturaNoAsitio2 = () => {
    //console.log(this.state.tipoFecha);
    // console.log(moment(this.state.fechaT).format("YYYY-MM-DD"))
    // console.log(this.state.casos2)
    // console.log(this.state.medicos)
    // console.log(moment(this.state.fechaT).day())
    // console.log(this.state.horaS+':00')
    if (this.state.correrF !== "SI" && this.state.nota.length > 0) {
      this.setState({
        fechaNuevaCita: "",
        horaC1: "",
      });
      let data = new FormData();
      data.append("cedulaP", this.state.cedulaAlumno);
      if (this.state.tipoFecha === "2")
        data.append(
          "fechaP",
          moment(this.state.fechaActual).format("YYYY-MM-DD")
        );
      if (this.state.tipoFecha === "1")
        data.append("fechaP", moment(this.state.diaI).format("YYYY-MM-DD"));
      data.append("tipoEvaluacionP", this.state.casos2);
      data.append("especialistaP", this.state.medicos);
      data.append("fechaE1", "");
      data.append("fechaE2", "");
      data.append("fechaE3", "");
      data.append("fechaE4", "");
      data.append("tipoEstudio", this.state.tipoEstudio);
      data.append("nuevaHora", this.state.horaC1);
      data.append("nuevaFecha", this.state.fechaNuevaCita);
      data.append("nota", this.state.nota);
      data.append("idasociarE", this.state.idasociar);
      data.append("numeroEstatus", this.state.numeroEstatus);
      data.append("boton", "guardarFacturaNoAsitio");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/asociarEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              estatusOn: false,
              facturaNoAsitioOn: false,
              bandera: "",
              // mensajealerta: true,
              // tituloM: "Menu Agendar Citas.",
              // cuerpoM: "La Cita se Guardo con Exito",
            });
            this.consultarHorarioEspecialista(this.state.medicos);
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cambio de Citas.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Agendar Citas.",
        cuerpoM: "Debe seleccionar un tipo de Consulta",
      });
    }
  };

  guardarFacturaNoAsitio = () => {
    //console.log(this.state.tipoFecha);
    // console.log(moment(this.state.fechaT).format("YYYY-MM-DD"))
    // console.log(this.state.casos2)
    // console.log(this.state.medicos)
    // console.log(moment(this.state.fechaT).day())
    // console.log(this.state.horaS+':00')
    if (
      this.state.correrF === "SI" &&
      this.state.nota.length > 0 &&
      this.state.fechaNuevaCita.length > 0 &&
      this.state.horaC1.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaP", this.state.cedulaAlumno);
      if (this.state.tipoFecha === "2")
        data.append(
          "fechaP",
          moment(this.state.fechaActual).format("YYYY-MM-DD")
        );
      if (this.state.tipoFecha === "1")
        data.append("fechaP", moment(this.state.diaI).format("YYYY-MM-DD"));
      data.append("tipoEvaluacionP", this.state.casos2);
      data.append("especialistaP", this.state.medicos);
      data.append("fechaE1", "");
      data.append("fechaE2", "");
      data.append("fechaE3", "");
      data.append("fechaE4", "");
      data.append("tipoEstudio", this.state.tipoEstudio);
      data.append("nuevaHora", this.state.horaC1);
      data.append("nuevaFecha", this.state.fechaNuevaCita);
      data.append("nota", this.state.nota);
      data.append("idasociarE", this.state.idasociar);
      data.append("numeroEstatus", this.state.numeroEstatus);
      data.append("boton", "guardarFacturaNoAsitio");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/asociarEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              estatusOn: false,
              facturaNoAsitioOn: false,
              bandera: "",
              // mensajealerta: true,
              // tituloM: "Menu Agendar Citas.",
              // cuerpoM: "La Cita se Guardo con Exito",
            });
            this.consultarHorarioEspecialista(this.state.medicos);
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cambio de Citas.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Agendar Citas.",
        cuerpoM: "Debe seleccionar un tipo de Consulta",
      });
    }
  };

  terapeutasDisponibles = (e) => {
    let data = new FormData();
    data.append("idespecialidad", e);
    data.append("nivel", this.props.usuario1.nivel);
    if (this.state.sede.length === 0)
      data.append("sede", this.props.usuario1.sede);
    if (this.state.sede.length > 0) data.append("sede", this.state.sede);
    data.append("boton", "consultaTerapeutasEspecialista");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximedicos = res.data.variable2;
        let xidiasDis = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            medicos: "",
            imedicos: [],
            idiasDis: [],
            imedicos: ximedicos,
            idiasDis: xidiasDis,
          });
        } else {
          this.setState({
            medicos: "",
            imedicos: [],
          });
        }
      });
  };

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  imprimir = () => {
    let data = new FormData();
    data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("arregloPaciente", JSON.stringify(this.state.iinformeFechasS));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteHorarioEspecialista.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscarI = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idmateria.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      if (this.state.cedulaAlumno === "") {
        data.append("boton", "buscarInasistenciaG");
      } else {
        data.append("boton", "buscarInasistencia");
      }

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/alumnoMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              xtotales: res.data.variable3,
            });
          } else {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              mensajealerta: true,
              tituloM: "Menu Consultar Inasistencias.",
              cuerpoM: res.data.variable3,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencias.",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionA.length > 0) {
      let data = new FormData();
      data.append("idseccionesA", this.state.idseccionA);
      data.append("boton", "eliminarSeccionesA");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesA(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Alumnos.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesA = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("cedulaAlumno", e);
        data.append("boton", "buscarSeccionesA");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xisecciones = res.data.variable3;
            let xiseccionesA = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                isecciones: xisecciones,
                iseccionesA: xiseccionesA,
              });
            } else {
              this.setState({
                iseccionesP: [],
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Grados Alumnos.",
          cuerpoM: "Debe existir un Alumno para realizar el proceso.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Seccion Alumnos.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Alumnos",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionA: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarIT = () => {
    let data = new FormData();
    //data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("cedulaTerapeuta", this.state.medicos);
    data.append("boton", "consultarInformeTerapeuta");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            iinformeFechasS: xiinformeFechasS,
            iinformeFechas: xiinformeFechas,
          });
        } else {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
          });
        }
      });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("desde", "citas");
      if (this.state.sede.length === 0)
        data.append("sede", this.props.usuario1.sede);
      if (this.state.sede.length > 0) data.append("sede", this.state.sede);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("desde", "citas");
    if (this.state.sede.length === 0)
      data.append("sede", this.props.usuario1.sede);
    if (this.state.sede.length > 0) data.append("sede", this.state.sede);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM:
              "No existe ningún Paciente con ese Nombre en esta Sede, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };
  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.verplanillaOn) {
      this.setState({
        verplanillaOn: false,
      });
    }
    if (!this.state.verplanillaOn) {
      this.setState({
        verplanillaOn: true,
      });
    }
  };
  incluirdatos = () => {
    //console.log(this.state.listaOn)
    if (this.state.incluirdatosOn) {
      this.setState({
        incluirdatosOn: false,
      });
    }
    if (!this.state.incluirdatosOn) {
      this.setState({
        incluirdatosOn: true,
      });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  buscarE = () => {
    let data = new FormData();
    data.append("boton", "Buscar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);

        let xterapeuta = res.data;
        this.setState({
          iterapeuta: xterapeuta,
        });
      });
  };

  onClickPrevNextLabel = ({ activeStartDate, value, view }) => {
    // console.log("vv:", activeStartDate, value, view);
    // console.log(value);
    this.setState({
      fechaSeleccionada: activeStartDate,
    });
  };

  onClickMonth = (e) => {
    this.setState({
      fechaSeleccionada: e,
    });

    // console.log(e);
    // console.log('mes');
  };
  valorInicial = (e) => {
    if (e === "2") {
      let xfecha = new Date(moment(this.state.fechaSeleccionada).format());

      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(xfecha.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.state.diaI = xdia1;
      this.state.diaF = xdia6;
      this.setState({
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
        sede: this.props.usuario1.sede,
      });
    }

    if (e === "1") {
      let xfecha = new Date(moment(this.state.fechaSeleccionada).format());
      //      let xfecha = new Date(e);
      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
        sede: this.props.usuario1.sede,
      });
    }
  };

  derecha = () => {
    if (this.state.tipoFecha === "2") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(7, "day").format()
      );

      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(xfecha.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.state.diaI = xdia1;
      this.state.diaF = xdia6;
      this.setState({
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
    if (this.state.tipoFecha === "1") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(1, "day").format()
      );

      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
  };
  izquierda = () => {
    // console.log(this.state.fechaSeleccionada);
    // console.log(this.state.fechaSeleccionada.getMonth());
    // console.log(this.state.fechaSeleccionada.getDay());
    // console.log(this.state.fechaSeleccionada.getDate());
    // let xfecha = new Date(
    //   moment(this.state.fechaSeleccionada).add(-7, "day").format()
    // );
    // console.log(xfecha);
    // console.log(xfecha.getMonth());
    // console.log(xfecha.getDay());
    // console.log(xfecha.getDate());
    //console.log(this.state.tipoFecha);
    if (this.state.tipoFecha === "2") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(-7, "day").format()
      );

      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(xfecha.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.state.diaI = xdia1;
      this.state.diaF = xdia6;
      this.setState({
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
    if (this.state.tipoFecha === "1") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(-1, "day").format()
      );

      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
  };
  onClickDay = (e) => {
    // console.log(e);
    // console.log(e.getMonth());
    // console.log(e.getDay());
    // console.log(e.getDate());
    if (this.state.tipoFecha === "2") {
      let xfecha = new Date(e);
      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(e.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.setState({
        diaI: xdia1,
        diaF: xdia6,
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos, xdia1, xdia6);
    }

    if (this.state.tipoFecha === "1") {
      let xfecha = new Date(e);
      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }

    // let data = new FormData();
    // data.append("idcaso", this.state.casos);
    // data.append("especialista", this.state.terapeuta);
    // data.append("dia", e.getDay());
    // data.append("fechaY", e.getFullYear());
    // data.append("fechaM", e.getMonth());
    // data.append("fechaD", e.getDate());
    // data.append("boton", "ConsultarDisponibilidadHora");
    // let config = {
    //   headers: { "Content-Type": "text/html" },
    // };
    // const xurl =
    //   "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    // axios
    //   .post(xurl, data, config)

    //   .then((res) => {
    //     let xhorasDias = res.data;
    //     this.setState({
    //       ihoraCita: xhorasDias,
    //       fechaCita: "paso",
    //     });
    //   });
  };

  asociarEvaluacion = () => {
    let data = new FormData();
    data.append("boton", "Consultar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcasos = res.data;
        this.setState({
          icasos: xcasos,
        });
      });
  };

  consultarHorarioEspecialista = (e, f, g) => {
    let data = new FormData();
    data.append("cedulaEspecialista", e);
    data.append("nivel", this.props.usuario1.nivel);
    if (this.state.sede.length === 0)
      data.append("sede", this.props.usuario1.sede);
    if (this.state.sede.length > 0) data.append("sede", this.state.sede);
    data.append("dia", this.state.diaI.getDay());
    data.append("tipoEva", this.state.casos);
    if (this.state.tipoFecha === "2") {
      console.log("f " + f);
      if (f !== undefined) {
        data.append("fechadia", moment(f).format("YYYY-MM-DD"));
      }
      if (g !== undefined) {
        data.append("fechafin", moment(g).format("YYYY-MM-DD"));
      }
      if (f === undefined) {
        data.append("fechadia", moment(this.state.diaI).format("YYYY-MM-DD"));
      }
      if (g === undefined) {
        data.append("fechafin", moment(this.state.diaF).format("YYYY-MM-DD"));
      }
    }
    if (this.state.tipoFecha === "1") {
      data.append("fechadia", moment(this.state.diaI).format("YYYY-MM-DD"));
    }

    data.append("tbusqueda", this.state.tipoFecha);
    data.append("boton", "consultarHorarioEspecialista");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xihorario = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            ihorario: [],
            verHorarioOn: true,
            ihorario: res.data.variable2,
          });
        } else {
          this.setState({
            verHorarioOn: false,
            ihorario: res.data.variable2,
          });
        }
      });
  };

  incluirEstatus = () => {
    //console.log(this.state.listaOn)
    if (this.state.estatusOn) {
      this.setState({
        estatusOn: false,
        bandera: "",
        alumno: "",
        numeroEstatus: "",
        idasociar: "",
      });
    }
    if (!this.state.estatusOn) {
      this.setState({
        estatusOn: true,
      });
    }
  };

  verNota = () => {
    let data = new FormData();
    if (this.state.tipoFecha === "1")
      data.append("fechadia", moment(this.state.diaI).format("YYYY-MM-DD"));
    if (this.state.tipoFecha === "2")
      data.append(
        "fechadia",
        moment(this.state.fechaActual).format("YYYY-MM-DD")
      );
    data.append("idasociarE", this.state.idasociar);
    data.append("estatus", this.state.numeroEstatus);

    data.append("boton", "verNotas");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          // this.consultarHorarioEspecialista(this.state.medicos);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consultar Observación.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  actualizarEstatus = (e) => {
    let data = new FormData();
    if (this.state.tipoFecha === "1")
      data.append("fechadia", moment(this.state.diaI).format("YYYY-MM-DD"));
    if (this.state.tipoFecha === "2")
      data.append(
        "fechadia",
        moment(this.state.fechaActual).format("YYYY-MM-DD")
      );
    data.append("idasociarE", this.state.idasociar);
    data.append("estatus", e);
    data.append("nota", this.state.nota);

    data.append("boton", "actualizarEstatus");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarHorarioEspecialista(this.state.medicos);
          this.setState({
            // alumno: f,
            // numeroEstatus: res.data.variable2,
            // idasociar:h,
          });
        }
      });
  };

  actualizarEstatus2 = () => {
    let data = new FormData();
    if (this.state.tipoFecha === "1")
      data.append("fechadia", moment(this.state.diaI).format("YYYY-MM-DD"));
    if (this.state.tipoFecha === "2")
      data.append(
        "fechadia",
        moment(this.state.fechaActual).format("YYYY-MM-DD")
      );
    data.append("idasociarE", this.state.idasociar);
    data.append("estatus", "4");
    data.append("nota", this.state.nota);

    data.append("boton", "actualizarEstatus");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarHorarioEspecialista(this.state.medicos);
          this.setState({
            // alumno: f,
            // numeroEstatus: res.data.variable2,
            // idasociar:h,
          });
        }
      });
  };

  consultarPaciente = (e, f, g, h, j, k) => {
    console.log("datos " + j + " - " + k);

    if (j !== "0" && j !== "5" && j !== "11") {
      //if (j !== "0" && j !== "5" && j !== "11") {
      //console.log("tiene datos " + h);
      this.state.bandera = "1";
      //this.setState({bandera:'1',});
      let data = new FormData();
      data.append("idasociarE", h);
      if (this.state.tipoFecha === "2") {
        data.append("fechadia", moment(k).format("YYYY-MM-DD"));
      }
      if (this.state.tipoFecha === "1") {
        data.append("fechadia", moment(this.state.diaI).format("YYYY-MM-DD"));
      }
      data.append("sede", this.state.sede);
      data.append("boton", "consultarstatus");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            // if (this.state.facturasAtrasadas === "0") {
            let xiultimaCita = res.data.variable5;

            if (this.state.tipoFecha === "1") {
              this.setState({
                fechaActual: moment(this.state.diaI).format("YYYY-MM-DD"),
              });
            }
            if (this.state.tipoFecha === "2") {
              this.setState({
                fechaActual: k,
              });
            }
            if (res.data.variable10===1){
              this.state.fechadeldia=this.state.fechaActual;
            }
            this.setState({
              estatusOn: true,
              alumno: f,
              numeroEstatus: res.data.variable2,
              idasociar: h,
              cedulaAlumno: res.data.variable3,
              tipoEva: res.data.variable4,
              iultimaCita: xiultimaCita,
              irecibosE: res.data.variable6,
              tipoEstu: res.data.variable7,
              facturasAtrasadas: res.data.variable8,
              ifacturasAtrasadas: res.data.variable9,
              estatusAnterior: j,
              autorizado:res.data.variable10,
            });
            // } else {
            //   this.setState({
            //     mensajealerta: true,
            //   tituloM: "Menu Agendar Citas.",
            //   cuerpoM: res.data.variable9,
            //     estatusOn: false,
            //     tipoEva: "",
            //     bandera: "",
            //     irecibosE: [],
            //   });
            // }
          } else {
            this.setState({
              estatusOn: false,
              tipoEva: "",
              bandera: "",
              irecibosE: [],
            });
          }
        });
    }
    //else {
    //   let xfechaT = "";
    //   let xdia = "";
    //   if (g >= xdia) {
    //     xdia = moment(this.state.diaI).format("DD");
    //     xfechaT = new Date(
    //       moment(this.state.diaI, "DD-MM-YYYY").add(g - xdia, "day")
    //     );
    //   }
    //   if (g < xdia) {
    //     xdia = moment(this.state.diaF).format("DD");
    //     xfechaT = new Date(
    //       moment(this.state.diaF, "DD-MM-YYYY").add(-(xdia - g), "day")
    //     );
    //   }

    //   this.setState({
    //     horaS: e.substring(0, 2),
    //     fechaT: moment(xfechaT).format("YYYY-MM-DD"),
    //     casos2: this.state.casos,
    //     verplanillaOn: true,
    //   });
    // }
  };

  consultarPaciente2 = (e, f, g, h, j, k) => {
    console.log("datos2 " + j + " - " + k);
    console.log("bandera: " + this.state.bandera);
    if (j === "0" && this.state.bandera === "") {
      let xfechaT = "";
      let xdia = "";
      if (g >= xdia) {
        xdia = moment(this.state.diaI).format("DD");
        xfechaT = new Date(
          moment(this.state.diaI, "DD-MM-YYYY").add(g - xdia, "day")
        );
      }
      if (g < xdia) {
        xdia = moment(this.state.diaF).format("DD");
        xfechaT = new Date(
          moment(this.state.diaF, "DD-MM-YYYY").add(-(xdia - g), "day")
        );
      }

      this.setState({
        horaS: e.substring(0, 2),
        fechaT: moment(xfechaT).format("YYYY-MM-DD"),
        casos2: this.state.casos,
        verplanillaOn: true,
      });
    }
  };

  guardarCita = () => {
    // console.log(this.state.cedulaAlumno)
    // console.log(moment(this.state.fechaT).format("YYYY-MM-DD"))
    // console.log(this.state.casos2)
    // console.log(this.state.medicos)
    // console.log(moment(this.state.fechaT).day())
    // console.log(this.state.horaS+':00')
    if (this.state.tipoEstudio.length > 0) {
      let data = new FormData();
      data.append("cedulaP", this.state.cedulaAlumno);
      if (this.state.tipoFecha === "2")
        data.append("fechaP", moment(this.state.fechaT).format("YYYY-MM-DD"));
      if (this.state.tipoFecha === "1")
        data.append("fechaP", moment(this.state.diaI).format("YYYY-MM-DD"));
      data.append("tipoEvaluacionP", this.state.casos2);
      data.append("especialistaP", this.state.medicos);
      data.append("fechaE1", "");
      data.append("fechaE2", "");
      data.append("fechaE3", "");
      data.append("fechaE4", "");
      data.append("tipoEstudio", this.state.tipoEstudio);
      data.append("hora", this.state.horaS + ":00");
      data.append("nivel", this.props.usuario1.nivel);
      if (this.state.sede.length === 0)
        data.append("sede", this.props.usuario1.sede);
      if (this.state.sede.length > 0) data.append("sede", this.state.sede);
      data.append("boton", "guardarAsociarE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/asociarEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              // mensajealerta: true,
              // tituloM: "Menu Agendar Citas.",
              // cuerpoM: "La Cita se Guardo con Exito",
            });
            this.guardarCalendario(res.data.variable2);
            this.incluirdatos();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Agendar Citas.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Agendar Citas.",
        cuerpoM: "Debe seleccionar un tipo de Consulta",
      });
    }
  };

  guardarCambio = () => {
    //console.log(this.state.tipoFecha);
    // console.log(moment(this.state.fechaT).format("YYYY-MM-DD"))
    // console.log(this.state.casos2)
    // console.log(this.state.medicos)
    // console.log(moment(this.state.fechaT).day())
    // console.log(this.state.horaS+':00')
    if (
      this.state.nota.length > 0 &&
      this.state.fechaNuevaCita.length > 0 &&
      this.state.horaC1.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaP", this.state.cedulaAlumno);
      if (this.state.tipoFecha === "2")
        data.append(
          "fechaP",
          moment(this.state.fechaActual).format("YYYY-MM-DD")
        );
      if (this.state.tipoFecha === "1")
        data.append("fechaP", moment(this.state.diaI).format("YYYY-MM-DD"));
      data.append("tipoEvaluacionP", this.state.casos2);
      data.append("especialistaP", this.state.medicos);
      data.append("fechaE1", "");
      data.append("fechaE2", "");
      data.append("fechaE3", "");
      data.append("fechaE4", "");
      data.append("tipoEstudio", this.state.tipoEstudio);
      data.append("nuevaHora", this.state.horaC1);
      data.append("nuevaFecha", this.state.fechaNuevaCita);
      data.append("nota", this.state.nota);
      data.append("idasociarE", this.state.idasociar);
      data.append("boton", "cambioHorario");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/asociarEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              estatusOn: false,
              cambioOn: false,
              bandera: "",
              // mensajealerta: true,
              // tituloM: "Menu Agendar Citas.",
              // cuerpoM: "La Cita se Guardo con Exito",
            });
            this.consultarHorarioEspecialista(this.state.medicos);
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cambio de Citas.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Agendar Citas.",
        cuerpoM: "Debe seleccionar un tipo de Consulta",
      });
    }
  };

  guardarTipoEstudio = () => {
    if (this.state.tipoEstudio.length > 0) {
      let data = new FormData();
      data.append("tipoEstudio", this.state.tipoEstudio);
      data.append("idasociarE", this.state.idasociar);
      data.append("boton", "actualizarEstudio");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              estatusOn: false,
              cambioEstudioOn: false,
              bandera: "",
            });
            this.consultarHorarioEspecialista(this.state.medicos);
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Cambio de Estudio.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Cambio de Estudio.",
        cuerpoM: "Debe seleccionar un tipo de Estudio",
      });
    }
  };

  guardarCalendario = (e) => {
    let data = new FormData();

    data.append("iditen", e);
    if (this.state.tipoFecha === "2") {
      data.append("dia", moment(this.state.fechaT).day());
      data.append("fecha", moment(this.state.fechaT).format("YYYY-MM-DD"));
    }
    if (this.state.tipoFecha === "1") {
      data.append("dia", moment(this.state.diaI).day());
      data.append("fecha", moment(this.state.diaI).format("YYYY-MM-DD"));
    }

    data.append("hora", this.state.horaS + ":00");
    data.append("boton", "guardarCalendario");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/asociarEvaluacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Agendar Citas.",
          cuerpoM: "La Cita se Guardo con Exito",
          incluirdatosOn: false,
        });
        // this.consultarCalendario(e);
        this.consultarHorarioEspecialista(this.state.medicos);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Agendar Citas.",
          cuerpoM: res.data.variable2,
        });
      }
    });
  };

  historia = () => {};
  consultaHorario = () => {
    this.consultarHorarioEspecialista(this.state.medicos);
  };

  componentDidMount() {
    this.buscarE();
    this.asociarEvaluacion();
    this.valorInicial(this.state.tipoFecha);
    // console.log(this.props.usuario1.sede)
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxcP col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE CITAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {this.state.habilitarReciOn === true && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <Recibo
                  cedulaPaciente={this.state.cedulaAlumno}
                  xidasociarE={this.state.idasociar}
                  handleCloseH={this.habilitarRecibo}
                  handleActualizarE={this.actualizarEstatus2}
                  fechaActual={this.state.fechaActual}
                  especialidad={this.state.casos}
                  sedes={this.state.sede}
                  tipoEstu={this.state.tipoEstu}
                  estatusAnterior={this.state.estatusAnterior}
                />
              </div>
            </div>
          )}

          {this.state.facturasAtrasadasOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasosF">Listado de Facturas Pendientes</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.facturasAt.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.ifacturasAtrasadas.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consulta.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable3}
                              </td>
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consulta.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable4}
                              </td>
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consulta.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable2}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.verplanillaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>
                      SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                      CORRESPONDIENTA A SU SELECCIÓN.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="fechas col-12">
                      <select
                        id="tbusqueda"
                        name="tbusqueda"
                        value={this.state.tbusqueda}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Buscar por --</option>
                        <option value="1">CEDULA/PASAPORTE</option>
                        <option value="2">NOMBRE PACIENTE</option>
                        {/* <option value="3">CURSO/GRADO</option>
                    <option value="4">TODOS LOS PACIENTES</option> */}
                      </select>
                    </div>
                  </div>

                  {this.state.tbusqueda < "3" && (
                    <div className="contenedorcedula1 row">
                      <input
                        className="form-control"
                        type="text"
                        name="identificacionp1"
                        id="identificacionp1"
                        autoComplete="off"
                        placeholder={
                          this.state.tbusqueda === "1"
                            ? "Identificación Paciente."
                            : "Nombre Paciente."
                        }
                        maxLength="300"
                        onChange={this.onChange.bind(this)}
                        value={this.state.identificacionp1.toUpperCase()}
                      />
                    </div>
                  )}
                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.buscarPlanilla.bind(this)}
                    >
                      Consultar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>

                    <button
                      className="boton"
                      onClick={this.cerrarLista2.bind(this)}
                    >
                      Salir
                    </button>
                  </div>

                  {this.state.listaOn && (
                    <div className="cont-tablamedi row">
                      <div className="tablamedi1 col-11">
                        <div className="cont-titulomedi col-12">
                          <span className="titulocasos">
                            Listado de Pacientes
                          </span>
                          <span
                            className="titulo-cerrarcasos"
                            onClick={this.cerrarLista.bind(this)}
                          >
                            X
                          </span>
                        </div>
                        {/* <span className="titulo-casos">Datos del Pago</span> */}
                        <div className="cont-listmedi row">
                          <div className="listmedi col-12">
                            <table className="tablame" border="1">
                              <tbody className="evocuerpo1">
                                {this.state.nalumno.map((item, i) => {
                                  return (
                                    <tr
                                      className={i % 2 === 0 ? "odd" : ""}
                                      key={item.variable1}
                                      id="tabla"
                                    >
                                      <td
                                        className="renglon"
                                        width="10"
                                        onClick={this.consulta.bind(
                                          this,
                                          item.variable1
                                        )}
                                      >
                                        {item.variable2}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.incluirdatosOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>
                      {this.state.cedulaAlumno}
                      {" - "}
                      {this.state.alumno}
                    </p>
                  </div>

                  <div className="row">
                    <div className="contenedor-cedula col-12">
                      {this.state.tipoFecha === "2" && (
                        <span className="fecha2">
                          Fecha:{" "}
                          {moment(this.state.fechaT).format("DD-MM-YYYY")}
                        </span>
                      )}
                      {this.state.tipoFecha === "1" && (
                        <span className="fecha2">
                          Fecha: {moment(this.state.diaI).format("DD-MM-YYYY")}
                        </span>
                      )}
                      <span className="fecha2">
                        Hora: {this.state.horaS}:00
                      </span>
                      {/* <span className="fecha2">{this.state.casos2}</span> */}
                    </div>
                    <div className="row">
                      <div className="fechas col-3">
                        <select
                          id="tipoEstudio"
                          name="tipoEstudio"
                          value={this.state.tipoEstudio}
                          onChange={this.onChange.bind(this)}
                        >
                          <option>-- Tipo Consulta --</option>
                          <option value="1">EVALUACIÓN</option>
                          <option value="2">TERAPIA</option>
                          <option value="3">TERAPIA PROVISIONAL</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.guardarCita.bind(this)}
                    >
                      Guardar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>

                    <button
                      className="boton"
                      onClick={this.incluirdatos.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.estatusOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorpj col-12">
                  <div id="div2" className="titulo-planillai">
                    <p>
                      {this.state.alumno + " - " + this.state.cedulaAlumno}
                      {/* {" - "} */}
                      {/* <span className="cont-tipoD">{"  "+this.state.tipoEva}</span> */}
                      {/* {" - "}
                      <p className="cont-tipoD">{this.state.tipoEva}</p> */}
                    </p>
                  </div>
                  <div id="div3" className="contenedorph ">
                    {this.state.iultimaCita.map((item, i) => {
                      return (
                        <p className="parrafo">
                          <span className="cont-tipoD">{item.variable1}</span>
                        </p>
                      );
                    })}

                    {/* <span className="cont-tipoD">
                        {"  " + this.state.tipoEva}
                      </span> */}
                  </div>
                  <div id="div1" className="row">
                    <div className="contenedor-cedula col-12">
                      <span>
                        {this.state.numeroEstatus === "1" && (
                          <FeatherIcon
                            className="ayuda-1"
                            icon="help-circle"
                            size="40px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "1" && (
                          <span className="fecha2">Sin Confirmar</span>
                        )}
                        {this.state.numeroEstatus === "2" && (
                          <FeatherIcon
                            className="confirmado-1"
                            icon="edit-3"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "2" && (
                          <span className="fecha2">Confirmado</span>
                        )}
                        {this.state.numeroEstatus === "3" && (
                          <FeatherIcon
                            className="enconsulta-1"
                            icon="check"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "3" && (
                          <span className="fecha2">En Consulta</span>
                        )}
                        {this.state.numeroEstatus === "4" && (
                          <FeatherIcon
                            className="dolar-1"
                            icon="dollar-sign"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "4" && (
                          <span className="fecha2">Pagado</span>
                        )}
                        {this.state.numeroEstatus === "5" && (
                          <FeatherIcon
                            className="cancelado-1"
                            icon="x"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "5" && (
                          <span className="fecha2">
                            Anulado (No asistirá más)
                          </span>
                        )}
                        {this.state.numeroEstatus === "6" && (
                          <FeatherIcon
                            className="noasistio-1"
                            icon="x-circle"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "6" && (
                          <span className="fecha2">No Asistio</span>
                        )}
                        {this.state.numeroEstatus === "7" && (
                          <FeatherIcon
                            className="espera-1"
                            icon="clock"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "7" && (
                          <span className="fecha2">En Espera</span>
                        )}
                        {this.state.numeroEstatus === "8" && (
                          <FeatherIcon
                            className="nocobrado-1"
                            icon="user-minus"
                            size="10px"
                            stroke="#999"
                          />
                        )}{" "}
                        {this.state.numeroEstatus === "8" && (
                          <span className="fecha2">No Cobrado</span>
                        )}
                        {this.state.numeroEstatus === "9" && (
                          <FeatherIcon
                            className="donacion-1"
                            icon="user-check"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "9" && (
                          <span className="fecha2">Donación</span>
                        )}
                        {this.state.numeroEstatus === "10" && (
                          <FeatherIcon
                            className="cancelado-3"
                            icon="x-square"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "10" && (
                          <span className="fecha2">Cancelado</span>
                        )}
                        {this.state.numeroEstatus === "11" && (
                          <FeatherIcon
                            className="cambio-1"
                            icon="refresh-cw"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "11" && (
                          <span className="fecha2">Cambio</span>
                        )}
                        {this.state.numeroEstatus === "12" && (
                          <FeatherIcon
                            className="chevron-1"
                            icon="chevron-down"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "12" && (
                          <span className="fecha2">Factura Pendiente</span>
                        )}
                        {this.state.numeroEstatus === "13" && (
                          <FeatherIcon
                            className="dolar-1"
                            icon="dollar-sign"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "13" && (
                          <span className="fecha2">Factura No Asistio</span>
                        )}
                        {this.state.numeroEstatus === "15" && (
                          <FeatherIcon
                            className="dolar-1"
                            icon="dollar-sign"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "15" && (
                          <FeatherIcon
                            className="espera-1"
                            icon="clock"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "15" && (
                          <span className="fecha2">No Asistio</span>
                        )}
                        {this.state.numeroEstatus === "16" && (
                          <FeatherIcon
                            className="dolar-1"
                            icon="dollar-sign"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "16" && (
                          <FeatherIcon
                            className="cancelado-2"
                            icon="x-square"
                            size="10px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "16" && (
                          <span className="fecha2">Cancelado</span>
                        )}
                      </span>{" "}
                      {this.state.fechaActual >= this.state.fechadeldia &&
                        this.state.numeroEstatus !== "11" &&
                        this.state.numeroEstatus !== "13" &&
                        this.state.numeroEstatus !== "15" &&
                        this.state.numeroEstatus !== "16" &&
                        this.state.numeroEstatus !== "4" &&
                        this.state.numeroEstatus !== "5" && (
                          <select
                            id="numeroEstatus"
                            name="numeroEstatus"
                            value={this.state.numeroEstatus}
                            onChange={this.onChange.bind(this)}
                          >
                            <option>-Seleccione Estatus-</option>
                            <option value="1">Sin Confirmar</option>
                            <option value="2">Confirmado</option>
                            <option value="3">En Consulta</option>
                            {this.state.numeroEstatus !== "1" && (
                              <option value="4">Facturar</option>
                            )}
                            <option value="5">Anulado (No asistirá más)</option>
                            <option value="6">No Asistio</option>
                            <option value="7">En Espera</option>
                            <option value="8">No Cobrado</option>
                            <option value="9">Donación</option>
                            <option value="10">Cancelado</option>
                            <option value="11">Cambio</option>
                            <option value="12">Factura Pendiente</option>
                            <option value="14">Modificar Estudio</option>
                          </select>
                        )}
                      {this.state.fechaActual >= this.state.fechadeldia &&
                        this.state.numeroEstatus === "4" && (
                          <select
                            id="numeroEstatus"
                            name="numeroEstatus"
                            value={this.state.numeroEstatus}
                            onChange={this.onChange.bind(this)}
                          >
                            <option>-Seleccione Estatus-</option>
                            <option value="15">No Asistio</option>
                            <option value="16">Cancelado</option>
                          </select>
                        )}
                      {this.state.numeroEstatus === "4" &&
                        this.state.irecibosE.length > 0 && (
                          <select
                            id="recibosE"
                            name="recibosE"
                            value={this.state.recibosE}
                            onChange={this.onChange5.bind(this)}
                          >
                            <option>- Recibos -</option>
                            {this.state.irecibosE.map((item, i) => {
                              return (
                                <option
                                  key={item.variable1}
                                  value={item.variable1}
                                >
                                  {item.variable1}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      {this.state.fechaActual < this.state.fechadeldia &&
                        this.state.numeroEstatus === "12" && (
                          <select
                            id="numeroEstatus"
                            name="numeroEstatus"
                            value={this.state.numeroEstatus}
                            onChange={this.onChange.bind(this)}
                          >
                            <option>-Seleccione Estatus-</option>
                            <option value="4">Facturar</option>
                          </select>
                        )}
                      <span onClick={this.verNota.bind(this)}>
                        {this.state.numeroEstatus === "5" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "6" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "10" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "11" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}

                        {this.state.numeroEstatus === "13" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "15" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}
                        {this.state.numeroEstatus === "16" && (
                          <FeatherIcon
                            className="lupa"
                            icon="search"
                            size="40px"
                            stroke="#999"
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    {/* <button
                      className="boton"
                      onClick={this.guardarCita.bind(this)}
                    >
                      Actualizar Estatus
                    </button> */}
                    {/* <button className="boton" onClick={this.incluirEstatus.bind(this)}>
                      Slir
                    </button> */}
                    <button
                      className="boton"
                      onClick={this.historia.bind(this)}
                    >
                      Historia
                    </button>

                    <button
                      className="boton"
                      onClick={this.incluirEstatus.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.notaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>INSERTE UN COMENTARIO CORTO</p>
                  </div>

                  <div className="row">
                    <div className="fechas col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="nota"
                        id="nota"
                        autoComplete="off"
                        placeholder="Observacion Corta."
                        maxLength="150"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nota.toUpperCase()}
                      />
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className={
                        this.state.nota.length <= 0 ? "botonOf" : "boton"
                      }
                      disabled={this.state.nota.length <= 0 ? true : false}
                      onClick={this.guardarNotas.bind(this)}
                    >
                      Guardar Nota
                    </button>
                    <button
                      className="boton"
                      onClick={this.consutarNotas.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.facturaNoAsitioOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>
                      INSERTE DATOS PARA CORRER LA FECHA DE PAGO A OTRA CITA O
                      TERAPIA
                    </p>
                  </div>
                  <div className="row">
                    <div className="fechas col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="nota"
                        id="nota"
                        autoComplete="off"
                        placeholder="Observacion Corta."
                        maxLength="150"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nota.toUpperCase()}
                      />
                    </div>
                    <div className="xcontenedorcedula1x col-12">
                      <label className="opcion2cB">Correr Fecha.</label>
                      <input
                        className="botonxB"
                        type="checkbox"
                        name="correrF"
                        id="correrF"
                        checked={this.state.correrF === "SI"}
                        onChange={this.onChange4.bind(this)}
                      />
                    </div>
                  </div>
                  {this.state.correrF === "SI" && (
                    <div className="row">
                      {/* <div className="fechas col-12">
                        <input
                          className="form-control"
                          type="text"
                          name="nota"
                          id="nota"
                          autoComplete="off"
                          placeholder="Observacion Corta."
                          maxLength="150"
                          onChange={this.onChange.bind(this)}
                          value={this.state.nota.toUpperCase()}
                        />
                      </div> */}
                      <div className="fechas col-4">
                        <select
                          id="horaC1"
                          name="horaC1"
                          value={this.state.horaC1}
                          onChange={this.onChange.bind(this)}
                        >
                          <option value="">Hora de Cita.</option>
                          <option value="07:00:00">07:00 am.</option>
                          <option value="08:00:00">08:00 am.</option>
                          <option value="09:00:00">09:00 am.</option>
                          <option value="10:00:00">10:00 am.</option>
                          <option value="11:00:00">11:00 am.</option>
                          <option value="12:00:00">12:00 am.</option>
                          <option value="13:00:00">01:00 pm.</option>
                          <option value="14:00:00">02:00 pm.</option>
                          <option value="15:00:00">03:00 pm.</option>
                          <option value="16:00:00">04:00 pm.</option>
                          <option value="17:00:00">05:00 pm.</option>
                          <option value="18:00:00">06:00 pm.</option>
                          <option value="19:00:00">07:00 pm.</option>
                        </select>
                      </div>
                      <div className="cont-personal col-2">
                        <label className="titulo-salario">
                          Fecha de Cita.
                          <input
                            className="form-control"
                            type="date"
                            name="fechaNuevaCita"
                            id="fechaNuevaCita"
                            autoComplete="off"
                            maxLength="10"
                            onChange={this.onChange.bind(this)}
                            value={
                              typeof this.state.fechaNuevaCita !== "undefined"
                                ? this.state.fechaNuevaCita
                                : ""
                            }
                          />
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="xcontenedorcedula1 col-12">
                    {this.state.correrF === "SI" && (
                      <button
                        className={
                          this.state.nota.length > 0 &&
                          this.state.horaC1.length > 0 &&
                          this.state.fechaNuevaCita.length > 0
                            ? "boton"
                            : "botonOf"
                        }
                        disabled={
                          this.state.nota.length > 0 &&
                          this.state.fechaNuevaCita.length > 0 &&
                          this.state.horaC1.length > 0
                            ? false
                            : true
                        }
                        onClick={this.guardarFacturaNoAsitio.bind(this)}
                      >
                        Guardar
                      </button>
                    )}
                    {this.state.correrF !== "SI" && (
                      <button
                        className={
                          this.state.nota.length > 0 ? "boton" : "botonOf"
                        }
                        disabled={this.state.nota.length > 0 ? false : true}
                        onClick={this.guardarFacturaNoAsitio2.bind(this)}
                      >
                        Guardar
                      </button>
                    )}
                    <button
                      className="boton"
                      onClick={this.facturaNoAsitio.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.cambioAnuladoOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>ANULAR EL TIPO DE EVALUACÓN</p>
                  </div>

                  <div className="row">
                    <div className="fechas col-3">
                      <select
                        id="nota"
                        name="nota"
                        value={this.state.nota}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Seleccione Tipo de Eliminación --</option>
                        <option value="1">POR FECHA</option>
                        <option value="2">ALTA</option>
                        <option value="3">NO VA A CONTINUAR</option>
                      </select>
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className={
                        this.state.nota.length > 0 ? "boton" : "botonOf"
                      }
                      disabled={this.state.nota.length > 0 ? false : true}
                      onClick={this.guardarEstatusAnu.bind(this)}
                    >
                      Guardar
                    </button>
                    <button
                      className="boton"
                      onClick={this.cambiarAnu.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.cambioEstudioOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>MODIFICAR EL TIPO DE EVALUACÓN</p>
                  </div>

                  <div className="row">
                    <div className="fechas col-3">
                      <select
                        id="tipoEstudio"
                        name="tipoEstudio"
                        value={this.state.tipoEstudio}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Tipo Consulta --</option>
                        <option value="1">EVALUACIÓN</option>
                        <option value="2">TERAPIA</option>
                      </select>
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className={
                        this.state.tipoEstudio.length > 0 ? "boton" : "botonOf"
                      }
                      disabled={
                        this.state.tipoEstudio.length > 0 ? false : true
                      }
                      onClick={this.guardarTipoEstudio.bind(this)}
                    >
                      Guardar Tipo Estudio
                    </button>
                    <button
                      className="boton"
                      onClick={this.cambiarEstu.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.cambioOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>INGRESAR LOS DATOS PARA REALIZAR EL CAMBIO DE HORARIO</p>
                  </div>

                  <div className="row">
                    <div className="fechas col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="nota"
                        id="nota"
                        autoComplete="off"
                        placeholder="Observacion del Cambio."
                        maxLength="150"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nota.toUpperCase()}
                      />
                    </div>
                    {/* <div className="fechas col-4">
                      <select
                        id="diaCalendario"
                        name="diaCalendario"
                        value={this.state.diaCalendario}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Buscar Dia --</option>
                        <option value="1">LUNES</option>
                        <option value="2">MARTES</option>
                        <option value="3">MIERCOLES</option>
                        <option value="4">JUEVES</option>
                        <option value="5">VIERNES</option>
                        <option value="6">SABADO</option>
                        
                      </select>
                    </div> */}
                    <div className="fechas col-4">
                      <select
                        id="horaC1"
                        name="horaC1"
                        value={this.state.horaC1}
                        onChange={this.onChange.bind(this)}
                      >
                        <option value="">Hora de Cita.</option>
                        <option value="07:00:00">07:00 am.</option>
                        <option value="08:00:00">08:00 am.</option>
                        <option value="09:00:00">09:00 am.</option>
                        <option value="10:00:00">10:00 am.</option>
                        <option value="11:00:00">11:00 am.</option>
                        <option value="12:00:00">12:00 am.</option>
                        <option value="13:00:00">01:00 pm.</option>
                        <option value="14:00:00">02:00 pm.</option>
                        <option value="15:00:00">03:00 pm.</option>
                        <option value="16:00:00">04:00 pm.</option>
                        <option value="17:00:00">05:00 pm.</option>
                        <option value="18:00:00">06:00 pm.</option>
                        <option value="19:00:00">07:00 pm.</option>
                      </select>
                    </div>
                    <div className="cont-personal col-2">
                      <label className="titulo-salario">
                        Fecha Nueva Cita.
                        <input
                          className="form-control"
                          type="date"
                          name="fechaNuevaCita"
                          id="fechaNuevaCita"
                          autoComplete="off"
                          maxLength="10"
                          onChange={this.onChange.bind(this)}
                          value={
                            typeof this.state.fechaNuevaCita !== "undefined"
                              ? this.state.fechaNuevaCita
                              : ""
                          }
                        />
                      </label>
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className={
                        this.state.nota.length > 0 &&
                        this.state.horaC1.length > 0 &&
                        this.state.fechaNuevaCita.length > 0
                          ? "boton"
                          : "botonOf"
                      }
                      disabled={
                        this.state.nota.length > 0 &&
                        this.state.fechaNuevaCita.length > 0 &&
                        this.state.horaC1.length > 0
                          ? false
                          : true
                      }
                      onClick={this.guardarCambio.bind(this)}
                    >
                      Guardar Cambio
                    </button>
                    <button
                      className="boton"
                      onClick={this.consutarCambios.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            {/* <div className="col-3"></div> */}

            <div className="calender col-3">
              <Calendar
                name="fechaSeleccionada"
                activeStartDate={this.state.fechaSeleccionada}
                // prevLabel={false}
                //prev2Label={false}
                //next2Label={false}
                // nextLabel={false}
                selectRange={false}
                showNavigation={true}
                showNeighboringMonth={true}
                onClickDay={(day) => {
                  this.onClickDay(day);
                }}
                //onActiveStartDateChange={this.onClickPrevNextLabel.bind(this)}
                //onClickDay={this.onClickDay.bind(this)}
                //onChange={this.onClickPrevLabel.bind(this)}
                onClickMonth={this.onClickMonth.bind(this)}
                value={this.state.fechaSeleccionada}
              />
              <div className="cont-forma row">
                <div className="col-5">
                  <div className="">
                    <select
                      id="tipoFecha"
                      name="tipoFecha"
                      value={this.state.tipoFecha}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="1">Por Dia</option>
                      <option value="2">Por Semana</option>
                    </select>
                  </div>
                </div>

                <div className="cont-Boton col-2">
                  <div className="btn-group" role="group">
                    <button
                      className="botonff1"
                      onClick={this.izquierda.bind(this)}
                    >
                      {"<"}
                    </button>
                    <button
                      className="botonff2"
                      onClick={this.derecha.bind(this)}
                    >
                      {">"}
                    </button>
                  </div>
                </div>
                <div className="cont-img row">
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="ayuda"
                        icon="help-circle"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Sin Confirmar</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="confirmado"
                        icon="edit-3"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Confirmado</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="enconsulta"
                        icon="check"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">En Consulta</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="dolar"
                        icon="dollar-sign"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Facturar ó Pagado</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="cancelado"
                        icon="x"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Anulado (No asistirá más)</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="noasistio"
                        icon="x-circle"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">No Asistio</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="espera"
                        icon="clock"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">En Espera</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="nocobrado"
                        icon="user-minus"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">No Cobrado</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="donacion"
                        icon="user-check"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Donación</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="cancelado-2"
                        icon="x-square"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Cancelado</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="cambio"
                        icon="refresh-cw"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Cambio</span>
                  </div>
                  <div className="col-10">
                    <span>
                      <FeatherIcon
                        className="chevron"
                        icon="chevron-down"
                        size="10px"
                        stroke="#999"
                      />
                    </span>{" "}
                    <span className="texto-img">Factura Pendiente</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="row">
                {this.props.usuario1.nivel === "1" && (
                  <div className="col-2">
                    <select
                      id="sede"
                      name="sede"
                      value={this.state.sede}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Seleccione Sucursal-</option>
                      <option value="1">Bella Vista</option>
                      <option value="2">Arraijan</option>
                      <option value="3">Chorrera</option>
                    </select>
                  </div>
                )}
                {this.state.sede.length > 0 && (
                  <div className="col-3">
                    <select
                      id="casos"
                      name="casos"
                      value={this.state.casos}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Seleccione Especialidad-</option>
                      {this.state.icasos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {this.state.sede.length > 0 && (
                  <div className="col-3">
                    <select
                      id="medicos"
                      name="medicos"
                      value={this.state.medicos}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Seleccione Especialista-</option>
                      {this.state.imedicos.map((item, i) => {
                        return (
                          <option
                            className={item.variable3 === 1 ? "dispo1" : ""}
                            key={i}
                            value={item.variable1}
                          >
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {this.state.medicos.length > 0 && (
                  <div className="col-3">
                    <select
                      id="diasDis"
                      name="diasDis"
                      value={this.state.diasDis}
                      // onChange={this.onChange.bind(this)}
                    >
                      <option>-Dias Disponibles-</option>
                      {this.state.idiasDis.map((item, i) => {
                        return (
                          this.state.medicos === item.variable1 && (
                            <option
                              className={item.variable3 === 1 ? "dispo1" : ""}
                              key={i}
                              value={item.variable1}
                            >
                              {item.variable2}
                            </option>
                          )
                        );
                      })}
                    </select>
                  </div>
                )}
                <div className="xcontenedorcedula1 col-12">
                  {/* <div className="xcontenedorcedula1 col-12"> */}
                  <button
                    className="boton"
                    onClick={this.consultaHorario.bind(this)}
                  >
                    Consultar Horario
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Otra Consulta
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                  {/* </div> */}
                </div>
              </div>

              {this.state.ihorario.length > 0 && (
                <div class="fluid row">
                  <div class="col-12 align-self-start">
                    {this.state.tipoFecha === "2" && (
                      <p className="mostrarFechas">
                        {moment(this.state.diaI).format("DD-MM-YYYY")}
                        {" - "}
                        {moment(this.state.diaF).format("DD-MM-YYYY")}
                      </p>
                    )}
                    {this.state.tipoFecha === "1" && (
                      <p className="mostrarFechas">
                        {moment(this.state.diaI).format("DD-MM-YYYY")}
                      </p>
                    )}
                  </div>
                  {this.state.tipoFecha === "1" && (
                    <div
                      id="detalleseleccion"
                      name="detalleseleccion"
                      class="este"
                    >
                      {this.state.ihorario.map((item, i) => {
                        return (
                          <div className="cont-diasterapia">
                            {i === 0 && (
                              // <div className="cont-diasterapia2 row">
                              <div className="padre row">
                                <div className="diasH">HORA</div>
                                <div className="diasSD">
                                  {moment(this.state.diaI).format("dddd LL")}
                                </div>
                              </div>
                            )}
                            {item.variable2 === "NO LABORA" && (
                              <div className="padre row">
                                <div className="XdiasHl">{item.variable1}</div>
                                <div className="XdiasSlD">{item.variable2}</div>
                              </div>
                            )}
                            {item.variable2 !== "NO LABORA" && (
                              <div className="padre row">
                                <div className="XdiasH">{item.variable1}</div>
                                <div
                                  className="XdiasSDC"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    i,
                                    item.variable9,
                                    item.variable8
                                  )}
                                >
                                  {/* <div className="counter">
                                    {item.variable2 !== "" ? (
                                      <span>
                                        {item.variable8 === "1" && (
                                          <FeatherIcon
                                            className="ayuda"
                                            icon="help-circle"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "2" && (
                                          <FeatherIcon
                                            className="confirmado"
                                            icon="edit-3"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "3" && (
                                          <FeatherIcon
                                            className="enconsulta"
                                            icon="check"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "4" && (
                                          <FeatherIcon
                                            className="dolar"
                                            icon="dollar-sign"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "5" && (
                                          <FeatherIcon
                                            className="cancelado"
                                            icon="x"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "6" && (
                                          <FeatherIcon
                                            className="noasistio"
                                            icon="x-circle"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "7" && (
                                          <FeatherIcon
                                            className="espera"
                                            icon="clock"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "8" && (
                                          <FeatherIcon
                                            className="nocobrado"
                                            icon="user-minus"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}{" "}
                                        {item.variable8 === "9" && (
                                          <FeatherIcon
                                            className="donacion"
                                            icon="user-check"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}
                                        {item.variable8 === "10" && (
                                          <FeatherIcon
                                            className="cancelado-2"
                                            icon="x-square"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}
                                        {item.variable8 === "11" && (
                                          <FeatherIcon
                                            className="cambio"
                                            icon="refresh-cw"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}
                                        {item.variable8 === "15" && (
                                          <FeatherIcon
                                            className="dolar"
                                            icon="dollar-sign"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}
                                        {item.variable8 === "15" && (
                                          <FeatherIcon
                                            className="noasistio"
                                            icon="x-circle"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}
                                        {item.variable8 === "13" && (
                                          <FeatherIcon
                                            className="dolar"
                                            icon="dollar-sign"
                                            size="10px"
                                            stroke="#999"
                                          />
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div> */}
                                  <div className="counter2">
                                    <div className="dato1">
                                      <div className="counter">
                                        {item.variable2 !== "" ? (
                                          <span>
                                            <FeatherIcon
                                              className={item.variable10}
                                              icon={item.variable10}
                                              stroke="#999"
                                            />
                                            {item.variable10 === "15" && (
                                              <FeatherIcon
                                                className="dolar"
                                                icon="dollar-sign"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                            {item.variable10 === "15" && (
                                              <FeatherIcon
                                                className="noasistio"
                                                icon="x-circle"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                            {item.variable10 === "16" && (
                                              <FeatherIcon
                                                className="dolar"
                                                icon="dollar-sign"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                            {item.variable10 === "16" && (
                                              <FeatherIcon
                                                className="cancelado-2"
                                                icon="x-square"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {item.variable2}
                                    </div>
                                    {item.variable11 !== "" ? (
                                      <div
                                        className={
                                          item.variable11 !== "" ? "dato2" : ""
                                        }
                                      >
                                        <div className="counter">
                                          <span>
                                            <FeatherIcon
                                              className={item.variable12}
                                              icon={item.variable12}
                                              stroke="#999"
                                            />
                                            {item.variable12 === "15" && (
                                              <FeatherIcon
                                                className="dolar"
                                                icon="dollar-sign"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                            {item.variable12 === "15" && (
                                              <FeatherIcon
                                                className="noasistio"
                                                icon="x-circle"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                            {item.variable12 === "16" && (
                                              <FeatherIcon
                                                className="dolar"
                                                icon="dollar-sign"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                            {item.variable12 === "16" && (
                                              <FeatherIcon
                                                className="cancelado-2"
                                                icon="x-square"
                                                size="10px"
                                                stroke="#999"
                                              />
                                            )}
                                          </span>
                                        </div>

                                        {item.variable11}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.state.tipoFecha === "2" && (
                    <div
                      id="detalleseleccion"
                      name="detalleseleccion"
                      class="este"
                    >
                      {this.state.ihorario.map((item, i) => {
                        return (
                          <div className="cont-diasterapia">
                            {i === 0 && (
                              // <div className="cont-diasterapia2 row">
                              <div className="padre row">
                                <div className="contenedorpadre">
                                  <div className="diasHT1">HORA</div>
                                  <div className="diasST1">
                                    LUNES {this.state.dia1}
                                  </div>
                                  <div className="diasST2">
                                    MARTES {this.state.dia2}
                                  </div>
                                  <div className="diasST3">
                                    MIERCOLES {this.state.dia3}
                                  </div>
                                  <div className="diasST4">
                                    JUEVES {this.state.dia4}
                                  </div>
                                  <div className="diasST5">
                                    VIERNES {this.state.dia5}
                                  </div>
                                  <div className="diasST6">
                                    SABADO {this.state.dia6}
                                  </div>
                                </div>
                                <div className="diasH">HORA</div>
                                <div className="diasS">
                                  LUNES {this.state.dia1}
                                </div>
                                <div className="diasS">
                                  MARTES {this.state.dia2}
                                </div>
                                <div className="diasS">
                                  MIERCOLES {this.state.dia3}
                                </div>
                                <div className="diasS">
                                  JUEVES {this.state.dia4}
                                </div>
                                <div className="diasS">
                                  VIERNES {this.state.dia5}
                                </div>
                                <div className="diasS">
                                  SABADO {this.state.dia6}
                                </div>
                              </div>
                            )}
                            {item.variable2 === "NO LABORA" && (
                              <div className="padre row">
                                <div className="XdiasHl">{item.variable1}</div>
                                <div className="XdiasSl">{item.variable2}</div>
                                <div className="XdiasSl">{item.variable3}</div>
                                <div className="XdiasSl">{item.variable4}</div>
                                <div className="XdiasSl">{item.variable5}</div>
                                <div className="XdiasSl">{item.variable6}</div>
                                <div className="XdiasSl">{item.variable7}</div>
                              </div>
                            )}
                            {item.variable2 !== "NO LABORA" && (
                              <div className="padre row">
                                <div className="XdiasH">{item.variable1}</div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={
                                    item.variable8 === "0" ||
                                    item.variable8 === "5" ||
                                    item.variable8 === "6" ||
                                    item.variable8 === "10"
                                      ? this.consultarPaciente2.bind(
                                          this,
                                          item.variable1,
                                          item.variable2,
                                          this.state.dia1,
                                          item.variable14,
                                          "0",
                                          item.variable20
                                        )
                                      : ""
                                  }
                                >
                                  {item.variable8 !== "0" && (
                                    <div
                                      className="counter2"
                                      onClick={this.consultarPaciente.bind(
                                        this,
                                        item.variable1,
                                        item.variable2,
                                        this.state.dia1,
                                        item.variable14,
                                        item.variable8,
                                        item.variable20
                                      )}
                                    >
                                      <div className="dato1">
                                        <div className="counter">
                                          {item.variable2 !== "" ? (
                                            <span className='iconosE'>
                                              {item.variable26 !== "15" &&
                                                item.variable26 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable26}
                                                    icon={item.variable26}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable26 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable26 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable26 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable26 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {item.variable2}
                                      </div>
                                      {item.variable32 !== "" ? (
                                        <div
                                          className={
                                            item.variable32 !== ""
                                              ? "dato2"
                                              : ""
                                          }
                                        >
                                          <div className="counter">
                                            <span className='iconosE'>
                                              {item.variable38 !== "15" &&
                                                item.variable38 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable38}
                                                    icon={item.variable38}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable38 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable38 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable38 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable38 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          </div>

                                          {item.variable32}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={
                                    item.variable9 === "0" ||
                                    item.variable9 === "5" ||
                                    item.variable9 === "6" ||
                                    item.variable9 === "10"
                                      ? this.consultarPaciente2.bind(
                                          this,
                                          item.variable1,
                                          item.variable3,
                                          this.state.dia2,
                                          item.variable15,
                                          "0",
                                          item.variable21
                                        )
                                      : ""
                                  }
                                >
                                  {item.variable9 !== "0" && (
                                    <div
                                      className="counter2"
                                      onClick={this.consultarPaciente.bind(
                                        this,
                                        item.variable1,
                                        item.variable3,
                                        this.state.dia2,
                                        item.variable15,
                                        item.variable9,
                                        item.variable21
                                      )}
                                    >
                                      <div className="dato1">
                                        <div className="counter">
                                          {item.variable3 !== "" ? (
                                            <span className='iconosE'>
                                              {item.variable27 !== "15" &&
                                                item.variable27 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable27}
                                                    icon={item.variable27}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable27 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable27 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable27 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable27 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {item.variable3}
                                      </div>
                                      {item.variable33 !== "" ? (
                                        <div
                                          className={
                                            item.variable33 !== ""
                                              ? "dato2"
                                              : ""
                                          }
                                        >
                                          <div className="counter">
                                            <span className='iconosE'>
                                              {item.variable39 !== "15" &&
                                                item.variable39 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable39}
                                                    icon={item.variable39}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable39 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable39 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable39 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable39 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          </div>

                                          {item.variable33}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={
                                    item.variable10 === "0" ||
                                    item.variable10 === "5" ||
                                    item.variable10 === "6" ||
                                    item.variable10 === "10"
                                      ? this.consultarPaciente2.bind(
                                          this,
                                          item.variable1,
                                          item.variable4,
                                          this.state.dia3,
                                          item.variable16,
                                          "0",
                                          item.variable22
                                        )
                                      : ""
                                  }
                                >
                                  {item.variable10 !== "0" && (
                                    <div
                                      className="counter2"
                                      onClick={this.consultarPaciente.bind(
                                        this,
                                        item.variable1,
                                        item.variable4,
                                        this.state.dia3,
                                        item.variable16,
                                        item.variable10,
                                        item.variable22
                                      )}
                                    >
                                      <div className="dato1">
                                        <div className="counter">
                                          {item.variable4 !== "" ? (
                                            <span className='iconosE'>
                                              {item.variable28 !== "15" &&
                                                item.variable28 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable28}
                                                    icon={item.variable28}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable28 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable28 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable28 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable28 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {item.variable4}
                                      </div>
                                      {item.variable34 !== "" ? (
                                        <div
                                          className={
                                            item.variable34 !== ""
                                              ? "dato2"
                                              : ""
                                          }
                                        >
                                          <div className="counter">
                                            <span className='iconosE'>
                                              {item.variable40 !== "15" &&
                                                item.variable40 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable40}
                                                    icon={item.variable40}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable40 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable40 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable40 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable40 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          </div>
                                          {item.variable34}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={
                                    item.variable11 === "0" ||
                                    item.variable11 === "5" ||
                                    item.variable11 === "6" ||
                                    item.variable11 === "10"
                                      ? this.consultarPaciente2.bind(
                                          this,
                                          item.variable1,
                                          item.variable5,
                                          this.state.dia4,
                                          item.variable17,
                                          "0",
                                          item.variable23
                                        )
                                      : ""
                                  }
                                >
                                  {item.variable11 !== "0" && (
                                    <div
                                      className="counter2"
                                      onClick={this.consultarPaciente.bind(
                                        this,
                                        item.variable1,
                                        item.variable5,
                                        this.state.dia4,
                                        item.variable17,
                                        item.variable11,
                                        item.variable23
                                      )}
                                    >
                                      <div className="dato1">
                                        <div className="counter">
                                          {item.variable5 !== "" ? (
                                            <span className='iconosE'>
                                              {item.variable29 !== "15" &&
                                                item.variable29 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable29}
                                                    icon={item.variable29}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable29 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}

                                              {item.variable29 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable29 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable29 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {item.variable5}
                                      </div>
                                      {item.variable35 !== "" ? (
                                        <div
                                          className={
                                            item.variable35 !== ""
                                              ? "dato2"
                                              : ""
                                          }
                                          onClick={this.consultarPaciente.bind(
                                            this,
                                            item.variable1,
                                            item.variable35,
                                            this.state.dia4,
                                            item.variable47,
                                            item.variable53,
                                            item.variable59
                                          )}
                                        >
                                          <div className="counter">
                                            <span className='iconosE'>
                                              {item.variable41 !== "15" &&
                                                item.variable41 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable41}
                                                    icon={item.variable41}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable41 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable41 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable41 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable41 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                            </span>
                                          </div>
                                          {item.variable35}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={
                                    item.variable12 === "0" ||
                                    item.variable12 === "5" ||
                                    item.variable12 === "6" ||
                                    item.variable12 === "10"
                                      ? this.consultarPaciente2.bind(
                                          this,
                                          item.variable1,
                                          item.variable6,
                                          this.state.dia5,
                                          item.variable18,
                                          "0",
                                          item.variable24
                                        )
                                      : ""
                                  }
                                >
                                  {item.variable12 !== "0" && (
                                    <div
                                      className="counter2"
                                      onClick={this.consultarPaciente.bind(
                                        this,
                                        item.variable1,
                                        item.variable6,
                                        this.state.dia5,
                                        item.variable18,
                                        item.variable12,
                                        item.variable24
                                      )}
                                    >
                                      <div className="dato1">
                                        <div className="counter">
                                          {item.variable6 !== "" ? (
                                            <span className='iconosE'>
                                              {item.variable30 !== "15" &&
                                                item.variable30 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable30}
                                                    icon={item.variable30}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable30 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable30 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable30 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable30 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              <FeatherIcon
                                                  className={item.variable70}
                                                  icon={item.variable70}
                                                  stroke="#999"
                                                />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {item.variable6}
                                      </div>
                                      {item.variable36 !== "" ? (
                                        <div
                                          className={
                                            item.variable36 !== ""
                                              ? "dato2"
                                              : ""
                                          }
                                        >
                                          <div className="counter">
                                            <span className='iconosE'>
                                              {item.variable42 !== "15" &&
                                                item.variable42 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable42}
                                                    icon={item.variable42}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable42 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable42 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable42 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable42 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              <FeatherIcon
                                                  className={item.variable71}
                                                  icon={item.variable71}
                                                  stroke="#999"
                                                />
                                            </span>
                                          </div>
                                          {item.variable36}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={
                                    item.variable13 === "0" ||
                                    item.variable13 === "5" ||
                                    item.variable13 === "6" ||
                                    item.variable13 === "10"
                                      ? this.consultarPaciente2.bind(
                                          this,
                                          item.variable1,
                                          item.variable7,
                                          this.state.dia6,
                                          item.variable19,
                                          "0",
                                          item.variable25
                                        )
                                      : ""
                                  }
                                >
                                  {item.variable13 !== "0" && (
                                    <div
                                      className="counter2"
                                      onClick={this.consultarPaciente.bind(
                                        this,
                                        item.variable1,
                                        item.variable7,
                                        this.state.dia6,
                                        item.variable19,
                                        item.variable13,
                                        item.variable25
                                      )}
                                    >
                                      <div className="dato1">
                                        <div className="counter">
                                          {item.variable7 !== "" ? (
                                            <span className='iconosE'>
                                              {item.variable31 !== "15" &&
                                                item.variable31 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable31}
                                                    icon={item.variable31}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable31 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable31 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable31 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable31 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              <FeatherIcon
                                                  className={item.variable72}
                                                  icon={item.variable72}
                                                  stroke="#999"
                                                />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {item.variable7}
                                      </div>
                                      {item.variable37 !== "" ? (
                                        <div
                                          className={
                                            item.variable37 !== ""
                                              ? "dato2"
                                              : ""
                                          }
                                        >
                                          <div className="counter">
                                            <span className='iconosE'>
                                              {item.variable43 !== "15" &&
                                                item.variable43 !== "16" && (
                                                  <FeatherIcon
                                                    className={item.variable43}
                                                    icon={item.variable43}
                                                    stroke="#999"
                                                  />
                                                )}
                                              {item.variable43 === "15" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable43 === "15" && (
                                                <FeatherIcon
                                                  className="noasistio"
                                                  icon="x-circle"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable43 === "16" && (
                                                <FeatherIcon
                                                  className="dolar"
                                                  icon="dollar-sign"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                              )}
                                              {item.variable43 === "16" && (
                                                <FeatherIcon
                                                  className="cancelado-2"
                                                  icon="x-square"
                                                  size="10px"
                                                  stroke="#999"
                                                />
                                                
                                              )}
                                              <FeatherIcon
                                                  className={item.variable73}
                                                  icon={item.variable73}
                                                  stroke="#999"
                                                />
                                            </span>
                                          </div>
                                          {item.variable37}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
  };
};
export default connect(mapStateToProps)(CitasAction);
