//dependencias
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//estilos
import "./SideBarAction.css";
import Alert from "../../modal/alert";
//componentes
import { outCliente1, outToken } from "../../accion/accion";
import { ActualizarMensajesChatI } from "../../API/CoreI";

class SideBarAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acordion1: false,
      acordion2: false,
      acordion3: false,
      acordion4: false,
      acordion5: false,
      acordion6: false,
      acordion7: false,
      acordion8: false,
      acordion9: false,
      opcionMenu: "1",
      sacordion1: false,
      sacordion2: false,
      sacordion3: false,
      sacordion4: false,
      sacordion5: false,
      sacordion6: false,
      sacordion7: false,
      sacordion8: false,
      sacordion9: false,
      sopcionMenu: "",
      smenuOn: false,
      ssacordion1: false,
      ssacordion2: false,
      ssacordion3: false,
      ssacordion4: false,
      ssacordion5: false,
      ssacordion6: false,
      ssacordion7: false,
      ssacordion8: false,
      ssacordion9: false,
      ssopcionMenu: "",
      ssmenuOn: false,
      menuOn: true,
      mensajealerta: false,
    };
    this.verificar = this.verificar.bind(this);
  }
  verificar(e) {
    // console.log([e.target.checked])
    if (e.target.checked === true) {
      this.setState({ [e.target.getAttribute("id")]: true });
    } else {
      this.setState({ [e.target.getAttribute("id")]: false });
    }
  }
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  cerrarsesion() {
    axios
      .get(
        "https://institutobilinguelasnaciones.com/terapias/backend/eliminarToken.php/?pas=" +
          this.props.usuario1.password +
          "&tok=" +
          this.props.token.token
      )
      .then((res) => {
        let tokenp = res.data.tokenEli;

        if (tokenp === true) {
          //console.log('resp-token - '+tokenp)

          this.props.outCliente1();
          this.props.outToken();
        } else {
          //console.log('Usuario no Tiene Token');
        }
      })
      .catch((err) => {
        //console.log('error - '+err.response.statu);
        if (err.response.status === 404) {
          //console.log('el usuario no existe ')
        }
      });
  }
  opmenu1 = () => {
    this.setState({ opcionMenu: "1", menuOn: true });
  };
  opmenu2 = () => {
    this.setState({ opcionMenu: "2", menuOn: true });
  };

  cerrarMenu = () => {
    this.setState({ opcionMenu: "", menuOn: false });
  };

  prueba() {
    return <Redirect to="/medicamento" />;
  }

  verificarReload = () => {
    let data = new FormData();
    data.append("cedula", this.props.usuario1.cedulaU);
    data.append("boton", "administrativo");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultarIP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Principal",
            cuerpoM:
              "Se Reiniciara el Sistema en 5 segundos ya que existe una nueva version, gracias.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 7000);
        } else {
        }
      });
  };

  // verificarVersion = () => {
  //   let data = new FormData();
  //   data.append("version", "1.010");
  //   data.append("boton", "consultarVersion");
  //   let config = {
  //     headers: { "Content-Type": "text/html" },
  //   };
  //   const xurl =
  //     "https://institutobilinguelasnaciones.com/terapias/backend/consultarIP.php";
  //   axios
  //     .post(xurl, data, config)

  //     .then((res) => {
  //       if (res.data.variable1) {
  //       } else {
  //         this.setState({
  //           mensajealerta: true,
  //           tituloM: "Menu Principal",
  //           cuerpoM:
  //             "Se Reiniciara el Sistema en 5 segundos ya que La Version Actual es Obsoleta, gracias.",
  //         });
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 5000);
  //       }
  //     });
  // };

  initData4 = async (e) => {
    let response = await ActualizarMensajesChatI(e);
    if (response.data.variable1) {
      console.log("paso");
    }
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let iprofesor = res.data.variable1;
      iprofesor.map((item, i) => {
        this.initData4(item.variable1);
      });
    });
  };

  componentDidMount() {
    this.verificarReload();
    // this.verificarVersion();
    // this.buscarProfesor();
  }
  render() {
    //console.log('nivel - '+this.props.usuario1.nivel);
    //console.log('usuario: '+this.props.usuario1.usuario);
    return (
      <div>
        {this.state.mensajealerta === true && (
          <Alert
            tituloMensaje={this.state.tituloM}
            cuerpoMensaje={this.state.cuerpoM}
            open={this.state.mensajealerta}
            handleClose={this.handleClose}
          />
        )}

        {this.state.opcionMenu === "1" && (
          <div className="contenedor-menus">
            <div className="menu">
              <ul>
                <li className="item">
                  <span className="btn1">Menu Administrativo</span>

                  <label className="btn" htmlFor="acordion1">
                    <i className="icono izquierda fas fa-file-medical" />
                    Cuentas Por Cobrar
                    {this.state.acordion1 === true ? (
                      <i className="icono-derecha-up fas fa-chevron-up" />
                    ) : (
                      <i className="icono derecha fas fa-chevron-down" />
                    )}
                  </label>
                  <input
                    type="checkbox"
                    id="acordion1"
                    checked={this.state.acordion1}
                    onChange={this.verificar}
                  />

                  <div className="smenu" id="informe">
                    <ul>
                      {/* <li><Link to="/pago">Reportar Pago</Link></li> */}
                      {/* <li>
                          <Link to="/cxc">Estado de Cuenta Alumno</Link>
                        </li> */}
                      {this.props.usuario1.nivel === "1" && (
                        <li>
                          <Link to="/cxcNuevo">
                            Estado de Cuenta Alumno Nuevo
                          </Link>
                        </li>
                      )}
                      {this.props.usuario1.nivel === "2" && (
                        <li>
                          <Link to="/cxcNuevo">
                            Estado de Cuenta Alumno Nuevo
                          </Link>
                        </li>
                      )}
                      {/* <li>
                          <Link to="/pagosrecibidos">Pagos Recibidos Web</Link>
                        </li>
                        <li>
                          <Link to="/pagosaprobados">Pagos Aprobados</Link>
                        </li>
                        <li>
                          <Link to="/mensualidadM">Mensua. Gen. Maternal</Link>
                        </li> */}
                      {/* <li>
                          <Link to="/mensualidad">Mensua. Gen. Primaria</Link>
                        </li> */}
                      {/* <li>
                            <Link to="/inscripcionG">Inscripcion General</Link>
                          </li> */}
                      {/* <li>
                          <Link to="/consutaInf">Consulta Terapias X Dia</Link>
                        </li> */}
                      <li>
                        <Link to="/reportes">Menu de Reportes Adm.</Link>
                      </li>
                      {/* <li>
                          <Link to="/reportesAca">Menu de Reportes Aca.</Link>
                        </li> */}
                      <li>
                        <Link to="/reciboI">Consulta Recibo Individual</Link>
                      </li>
                      <li>
                        <Link to="/referencia">
                          Consulta Referencia de Pago
                        </Link>
                      </li>
                      {/* <li>
                          <Link to="/planiH">Planificaciones Habilitadas</Link>
                        </li> */}
                    </ul>
                  </div>
                </li>

                <li className="item">
                  <label className="btn" htmlFor="acordion2">
                    <i className="icono izquierda far fa-edit" />
                    Administrativo
                    {this.state.acordion2 === true ? (
                      <i className="icono-derecha-up fas fa-chevron-up" />
                    ) : (
                      <i className="icono derecha fas fa-chevron-down" />
                    )}
                  </label>
                  <input
                    type="checkbox"
                    id="acordion2"
                    checked={this.state.acordion2}
                    onChange={this.verificar}
                  />

                  <div className="smenu" id="listado">
                    <ul>
                      {/* <li>
                        <Link to="/planillai">Planillas de Inscripción</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/adjuntos">Documentos Paciente</Link>
                      </li> */}
                      {/*   <li>
                          <Link to="/reinscripcion">
                            Reinscripción de Alumnos
                          </Link>
                        </li>
                        <li>
                          <Link to="/extension">Extencion Maternal C</Link>
                        </li>
                        <li>
                          <Link to="/planillaV">
                            Planillas de Inscripción Veranitos
                          </Link>
                        </li> */}
                      {/* <li>
                          <Link to="/maternalVeranito">
                            Marcar Veranitos en Maternales
                          </Link>
                        </li>
                        <li>
                          <Link to="/seccionVeranito">
                            Seleccionar Sección Veranitos
                          </Link>
                        </li> */}
                      <li>
                        <Link to="/correos">Menu de Correos</Link>
                      </li>
                      <li>
                        <Link to="/correosi">Correos Representantes</Link>
                      </li>
                      {/* <li>
                          <Link to="/correosMu">Correos Masivos</Link>
                        </li> */}
                    </ul>
                  </div>
                </li>

                {/* <li className="item" ><Link to="/espera" className="btn"><i className="icono izquierda fas fa-user-clock"></i>Lista de Espera</Link></li> */}
                <li className="item">
                  <label className="btn" htmlFor="acordion3">
                    <i className="icono izquierda fas fa-user-clock" />
                    Invent./Facturación
                    {this.state.acordion3 === true ? (
                      <i className="icono-derecha-up fas fa-chevron-up" />
                    ) : (
                      <i className="icono derecha fas fa-chevron-down" />
                    )}
                  </label>
                  <input
                    type="checkbox"
                    id="acordion3"
                    checked={this.state.acordion3}
                    onChange={this.verificar}
                  />

                  <div className="smenu" id="listaespera">
                    <ul>
                      <li>
                        <Link to="/clientes">Maestro de Clientes</Link>
                      </li>
                      <li>
                        <Link to="/inventario">Maestro de Inventario</Link>
                      </li>
                      {/* <li>
                          <Link to="/inventarioL">
                            Registrar Inventario Logico
                          </Link>
                        </li> */}
                      {/* <li>
                          <Link to="/despachoP">Despachar Pendientes</Link>
                        </li> */}
                      <li>
                        <Link to="/entradasInventario">
                          Ingresar Productos al Inventario
                        </Link>
                      </li>
                      {/* <li>
                          <Link to="/facturacion">Facturación Admi.</Link>
                        </li> */}
                      {/* {this.props.usuario1.cedulaU !== "1234567890" && ( */}
                      <li>
                        <Link to="/recepcion">Facturación</Link>
                      </li>
                      {/* )} */}
                      {/* {this.props.usuario1.cedulaU !== "1234567890" && (
                          <li>
                            <Link to="/facturaTerapia">
                              Facturación Terapia
                            </Link>
                          </li>
                        )} */}
                      {/* <li>
                          <Link to="/facturaRe">Aprobación Recibos</Link>
                        </li> */}
                      <li>
                        <Link to="/cierreRe">Cierre de Caja</Link>
                      </li>
                      {/* <li>
                          <Link to="/cambioRefe">Recibos sin Referencias</Link>
                        </li> */}
                      <li>
                        <Link to="/notaCredito">Nota de Credito</Link>
                      </li>
                      {/* <li>
                          <Link to="/notaCreditoM">Nota de Credito Manual</Link>
                        </li> */}
                      <li>
                        <Link to="/cxcCli">Estado de Cuenta Cliente</Link>
                      </li>
                      <li>
                        <Link to="/reporteInvFac">Reportes</Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="item">
                  <label className="btn" htmlFor="acordion4">
                    <i className="icono izquierda fas fa-user-clock" />
                    EGRESOS
                    {this.state.acordion4 === true ? (
                      <i className="icono-derecha-up fas fa-chevron-up" />
                    ) : (
                      <i className="icono derecha fas fa-chevron-down" />
                    )}
                  </label>
                  <input
                    type="checkbox"
                    id="acordion4"
                    checked={this.state.acordion4}
                    onChange={this.verificar}
                  />

                  <div className="smenu" id="listaespera">
                    <ul>
                      <li>
                        <Link to="/proveedores">Crear Proveedores</Link>
                      </li>
                      <li>
                        <Link to="/cxp">Factura y Pago Proveedores</Link>
                      </li>
                      {/* <li>
                          <Link to="/notaCreditoP">Nota de Credito</Link>
                        </li> */}
                      <li>
                        <Link to="/registroCompras">
                          {" "}
                          Descripción de Compras{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/reporteCxP">Reportes Egresos</Link>
                      </li>
                      <li>
                        <Link to="/verPagos">Consultar Pagos Prov.</Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="item">
                  <label className="btn" htmlFor="acordion5">
                    <i className="icono izquierda fas fa-folder-open" />
                    Configurar Sistema
                    {this.state.acordion5 === true ? (
                      <i className="icono-derecha-up fas fa-chevron-up" />
                    ) : (
                      <i className="icono derecha fas fa-chevron-down" />
                    )}
                  </label>
                  <input
                    type="checkbox"
                    id="acordion5"
                    checked={this.state.acordion5}
                    onChange={this.verificar}
                  />

                  <div className="smenu" id="membrete">
                    <ul>
                      {/* <li><Link to="#" onClick={this.cartas.bind(this)} >Para Cartas</Link></li> */}
                      <li>
                        <Link to="/donantes">Maestro de Donantes</Link>
                      </li>
                      <li>
                        <Link to="/asociarD">Asociar Donantes</Link>
                      </li>
                      {/* <li>
                          <Link to="/configuracion">
                            Crear Niveles y Grados
                          </Link>
                        </li>
                        <li>
                          <Link to="/grados">Crear Grados Primaria</Link>
                        </li>
                        <li>
                          <Link to="/conceptos">Crear Conceptos</Link>
                        </li>
                        <li>
                          <Link to="/anioescolarT">
                            Seleccionar Año Escolar a Trabajar
                          </Link>
                        </li>*/}
                      <li>
                        <Link to="/casos">Maestro Especialidad</Link>
                      </li>
                      <li>
                        <Link to="/medicos">Maestro de Especialistas</Link>
                      </li>
                      <li>
                        <Link to="/anioescolar">Crear y Activar Año</Link>
                      </li>
                      {/* <li>
                        <Link to="/usuarios">Crear Usuarios Sistema</Link>
                      </li> */}
                      {/* <li>
                          <Link to="/codigoQR">Crear Codigo QR</Link>
                        </li> */}
                    </ul>
                  </div>
                </li>

                <li className="item">
                  <label className="btn" htmlFor="acordion6">
                    <i className="icono izquierda fas fa-user-clock" />
                    PACIENTES
                    {this.state.acordion6 === true ? (
                      <i className="icono-derecha-up fas fa-chevron-up" />
                    ) : (
                      <i className="icono derecha fas fa-chevron-down" />
                    )}
                  </label>
                  <input
                    type="checkbox"
                    id="acordion6"
                    checked={this.state.acordion6}
                    onChange={this.verificar}
                  />

                  <div className="smenu" id="listaespera">
                    <ul>
                      <li>
                        <Link to="/planillai">Planillas de Inscripción</Link>
                      </li>
                      <li>
                        <Link to="/citas">Citas.</Link>
                      </li>
                      <li>
                        <Link to="/consutaInf">Consulta de Informes</Link>
                      </li>
                      <li>
                        <Link to="/consutaInf2">Horarios de Pacientes</Link>
                      </li>
                      <li>
                        <Link to="/consutaInf3">Horarios de Especialistas</Link>
                      </li>
                      {this.props.usuario1.password === "Cjlp197211" && (
                        <li>
                          <Link to="/becados">Pacientes Becados</Link>
                        </li>
                      )}
                      {this.props.usuario1.password === "13011970" && (
                        <li>
                          <Link to="/becados">Pacientes Becados</Link>
                        </li>
                      )}
                      {this.props.usuario1.password === "LEO6853" && (
                        <li>
                          <Link to="/becados">Pacientes Becados</Link>
                        </li>
                      )}
                      <li>
                        <Link to="/consutaInfG">
                          Archivos Adjuntos Pacientes
                        </Link>
                      </li>
                      <li>
                        <Link to="/reporteCita">Reporte de Citas</Link>
                      </li>
                      <li>
                        <Link to="/reporteOtros">Otros Reportes</Link>
                      </li>
                      {this.props.usuario1.password === "Cjlp197211" && (
                        <li>
                          <Link to="/autorizarMEAgenda">
                            Atorizar Cobro del Paciente
                          </Link>
                        </li>
                      )}
                       {this.props.usuario1.password === "13011970" && (
                        <li>
                          <Link to="/autorizarMEAgenda">
                            Atorizar Cobro del Paciente
                          </Link>
                        </li>
                      )}
                      {/* <li>
                        <Link to="/reporteNotasP">
                          Reporte Por Evaluación de la Dosificacion
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/NotasPendiente">
                          Reporte Evaluaciones Pendiente por Notas
                        </Link> 
                      </li>*/}
                      {/* <li>
                        <Link to="/permisosNotas">Permisos Eliminar Notas</Link>
                      </li> */}
                    </ul>
                  </div>
                </li>

                {/* <li className="item">
                    <label className="btn" htmlFor="acordion7">
                      <i className="icono izquierda fas fa-user-clock" />
                      DOSIFICACIONES DIAGNOSTICO
                      {this.state.acordion7 === true ? (
                        <i className="icono-derecha-up fas fa-chevron-up" />
                      ) : (
                        <i className="icono derecha fas fa-chevron-down" />
                      )}
                    </label>
                    <input
                      type="checkbox"
                      id="acordion7"
                      checked={this.state.acordion7}
                      onChange={this.verificar}
                    />

                    <div className="smenu" id="listaespera">
                      <ul>
                        <li>
                          <Link to="/dosificarDEAd">
                            Asignar Nombre de Evaluaciones
                          </Link>
                        </li>
                        <li>
                          <Link to="/dosificarEvaAd">
                            Dosificación por Profesor
                          </Link>
                        </li>
                        <li>
                          <Link to="/permisosNotasD">
                            Permisos Eliminar Notas
                          </Link>
                        </li>
                        <li>
                          <Link to="/reporteSemanaDPD">
                            Reporte Por Semana Dosificacion Diagnostico
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="item">
                    <label className="btn" htmlFor="acordion8">
                      <i className="icono izquierda fas fa-user-clock" />
                      DOSIFICACIONES 1ER. NIVEL
                      {this.state.acordion8 === true ? (
                        <i className="icono-derecha-up fas fa-chevron-up" />
                      ) : (
                        <i className="icono derecha fas fa-chevron-down" />
                      )}
                    </label>
                    <input
                      type="checkbox"
                      id="acordion8"
                      checked={this.state.acordion8}
                      onChange={this.verificar}
                    />

                    <div className="smenu" id="listaespera">
                      <ul>
                        <li>
                          <Link to="/dosificarDEPN">
                            Asignar Nombre de Evaluaciones
                          </Link>
                        </li>
                        <li>
                          <Link to="/dosificarEvaPN">
                            Dosificación por Profesor
                          </Link>
                        </li>
                        <li>
                          <Link to="/reporteSemanaDPD">
                            Reporte Por Semana Dosificacion
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="item">
                    <label className="btn" htmlFor="acordion9">
                      <i className="icono izquierda fas fa-user-clock" />
                      BOLETIN MATERNAL
                      {this.state.acordion9 === true ? (
                        <i className="icono-derecha-up fas fa-chevron-up" />
                      ) : (
                        <i className="icono derecha fas fa-chevron-down" />
                      )}
                    </label>
                    <input
                      type="checkbox"
                      id="acordion9"
                      checked={this.state.acordion9}
                      onChange={this.verificar}
                    />

                    <div className="smenu" id="listaespera">
                      <ul>
                        <li>
                          <Link to="/tiposArea">Asignar Tipos de Area</Link>
                        </li>
                        <li>
                          <Link to="/nombresAreas">
                            Nombres de Area a Evaluar
                          </Link>
                        </li>
                        <li>
                          <Link to="/escala">Escala Literal</Link>
                        </li>
                        <li>
                          <Link to="/evaluacionM">Notas Maternal</Link>
                        </li>
                      </ul>
                    </div>
                  </li> */}

                <li className="item">
                  <Link to="/nomina" className="btn">
                    <i className="icono izquierda far fa-newspaper"></i>Nomina
                    Personal
                  </Link>
                </li>
                <li className="item">
                  {/* <Link to="#" className="btn"><i className="icono izquierda fas fa-sign-in-alt"></i>Iniciar Sesion</Link> */}
                  <Link
                    to="/"
                    className="btn"
                    onClick={this.cerrarMenu.bind(this)}
                  >
                    <i className="icono izquierda fas fa-sign-out-alt"></i>
                    Salir al Menu Principal
                  </Link>
                </li>
              </ul>
            </div>
            {/* <Chat /> */}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  outCliente1,
  outToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarAction);
