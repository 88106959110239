import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import renderHTML from "react-render-html";
import FeatherIcon from "feather-icons-react";
//componentes
import "./autorizarMEPlani.css";
import Modal from "../modal2/modal";
import Alert from "../../modal/alert";

class AutorizarMEAgenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      fechaSeleccion: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      dateArray: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      trimestre: "",
      idmateria: "",
      verplanillaOn: false,
      mostrarOn: false,
      nalumno: [],
      ianoEscolar: [],
      icursos: [],
      ifechaPlani: [],
      someHTML: [],
      someHTML2: [],
      iprofesor: [],
      iautorizar: [],
      fechaTemporal: "",
      idagenda: "",
      identificacionp100: "",
      listaOn: false,
      alumno: "",
      cedulaAlumno: "",
    };
  }

  limpiar = () => {
    this.setState({
      fecha: "",
      fechaSeleccion: "",
      ifechamedi: [],
      mensajealerta: false,
      mostrarOn: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      trimestre: "",
      idmateria: "",
      someHTML: [],
      someHTML2: [],
      iautorizar: [],
      verplanillaOn: false,
      nalumno: [],
      dateArray: [],
      fechaTemporal: "",
      idagenda: "",
      identificacionp100: "",
      listaOn: false,
      alumno: "",
      cedulaAlumno: "",
    });
  };
  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  guardar = () => {
    let data = new FormData();
    data.append("cedula", this.state.cedulaAlumno);
    data.append("paciente", this.state.alumno);
    data.append("boton", "guardar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/autorizarMEAgenda.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            identificacionp100: "",
            alumno: "",
            cedulaAlumno: "",
          });
          this.consultar();
        } else {
          //this.setState({});
        }
      });
  };

  eliminar = (e) => {
    let data = new FormData();

    data.append("idautorizar", e);
    data.append("boton", "eliminar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/autorizarMEAgenda.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultar();
        } else {
          //this.setState({});
        }
      });
  };

  consultar = () => {
    let form = new FormData();
    form.append("boton", "consultar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/autorizarMEAgenda.php";
    axios.post(xurl, form, config).then((res) => {
      let xiautorizar = res.data.variable2;
      this.setState({
        iautorizar: xiautorizar,
      });
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  handleClose2 = () => {
    this.setState({ mostrarOn: false });
    this.consulta();
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133);
          if (res.data.variable136) {
            if (
              res.data.variable129 === "MADRE" ||
              res.data.variable129 === "PADRE" ||
              res.data.variable129 === "OTRO"
            ) {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedulaAlumno: res.data.variable4,
                identificacionp100:
                  res.data.variable1 + " " + res.data.variable2,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedulaAlumno: res.data.variable4,
                identificacionp100:
                  res.data.variable1 + " " + res.data.variable2,
                listaOn: false,
                verplanillaOn: false,
              });
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp100.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp100);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (
              res.data.variable129 === "MADRE" ||
              res.data.variable129 === "PADRE" ||
              res.data.variable129 === "OTRO"
            ) {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                //cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                identificacionp100:
                  res.data.variable1 + " " + res.data.variable2,
              });
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                //cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                identificacionp100:
                  res.data.variable1 + " " + res.data.variable2,
              });
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp100);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  componentDidMount() {
    this.consultar();
    //this.buscarCurso();
  }

  ax = () => {};
  render() {
    //console.log(this.state.fecha1)
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>AUTORIZAR COBRANZA A EL PACIENTE. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                {/* {this.state.verplanillaOn && ( */}

                <div className="titulo-cxc col-12">
                  <div className="titulo-planillai col-12">
                    <p>
                      SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                      CORRESPONDIENTA A SU SELECCIÓN.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="fechas col-12">
                      <select
                        id="tbusqueda"
                        name="tbusqueda"
                        value={this.state.tbusqueda}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Buscar por --</option>
                        <option value="1">CEDULA/PASAPORTE</option>
                        <option value="2">NOMBRE PACIENTE</option>
                      </select>
                    </div>
                  </div>

                  {this.state.tbusqueda < "3" && (
                    <div className="contenedorcedula1 row">
                      <input
                        className="form-controlx"
                        type="text"
                        name="identificacionp100"
                        id="identificacionp100"
                        autoComplete="off"
                        placeholder={
                          this.state.tbusqueda === "1"
                            ? "Identificación Paciente."
                            : "Nombre Paciente."
                        }
                        maxLength="300"
                        onChange={this.onChange.bind(this)}
                        value={this.state.identificacionp100.toUpperCase()}
                      />
                    </div>
                  )}

                  <div className="xcontenedorcedula1 col-12">
                    {this.state.cedulaAlumno.length > 0 && (
                      <button
                        className="boton2"
                        onClick={this.guardar.bind(this)}
                      >
                        Agregar a Cobranza
                      </button>
                    )}
                    <button
                      className="boton"
                      onClick={this.buscarPlanilla.bind(this)}
                    >
                      Consultar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>

                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                  </div>

                  {this.state.listaOn && (
                    <div className="cont-tablamedi row">
                      <div className="tablamedi1 col-11">
                        <div className="cont-titulomedi col-12">
                          <span className="titulocasos">
                            Listado de Pacientes
                          </span>
                          <span
                            className="titulo-cerrarcasos"
                            onClick={this.cerrarLista.bind(this)}
                          >
                            X
                          </span>
                        </div>
                        {/* <span className="titulo-casos">Datos del Pago</span> */}
                        <div className="cont-listmedi row">
                          <div className="listmedi col-12">
                            <table className="tablame" border="1">
                              <tbody className="evocuerpo1">
                                {this.state.nalumno.map((item, i) => {
                                  return (
                                    <tr
                                      className={i % 2 === 0 ? "odd" : ""}
                                      key={item.variable1}
                                      id="tabla"
                                    >
                                      <td
                                        className="renglon"
                                        width="10"
                                        onClick={this.consulta.bind(
                                          this,
                                          item.variable1
                                        )}
                                      >
                                        {item.variable2}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* )} */}
              </div>
            </div>
          </div>

          <div className="container col-12">
            <div className="titulo-cxc ">
              <p>COBRANZA ACTIVA POR 24 HORAS. </p>
              <table className="tablame" border="1">
                <tbody className="evocuerpo1">
                  {this.state.iautorizar.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : ""}
                        key={item.variable1}
                        id="tabla"
                      >
                        <td className="renglon" width="10">
                          {item.variable2 +
                            " - " +
                            item.variable3 +
                            " " +
                            item.variable4}
                        </td>
                        <td
                          className="renglon"
                          width="10"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        >
                          <FeatherIcon
                            icon="delete"
                            size="30px"
                            stroke="#999"
                            color="red"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(AutorizarMEAgenda);
